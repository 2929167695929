import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { AgGridReact } from "ag-grid-react";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  IoSendOutline,
} from "react-icons/io5";
import { createPortal } from "react-dom";

import "../../../styles.css";
import "./PlannedSupplyReceiptsGrid.css";
import {
  agGridNumberFormatter,
  getToastOptions,
} from "../../../shared/functions";
import { ReplenishmentPlanTabFetchAPI } from "../../../models/APIResponses.model";
import PlannedSupplyReceipt from "../../../models/ReplenishmentPlan/PlannedSupplyReceipt.model";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  PLANNED_SUPPLY_RECEIPTS_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import { RootState } from "../../../store";
import useFetch from "../../../hooks/useFetchMSAL";
import Modal from "../../UI/Modal/Modal";

const PlannedSupplyReceiptsGrid = (props: { gridHeight: string }) => {
  const [, fetchData] = useFetch([]);
  // States
  const [gridRowData, setGridRowData] = useState<PlannedSupplyReceipt[]>([]);
  const agGridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  
  // Variables
  const rejectRecommendedDateModalId: string = "rejectRecommendedDateModal";
  const acceptRecommendedDateModalId: string = "acceptRecommendedDateModal";
  const modalCloseBtnRef1 = useRef<HTMLButtonElement>(null);
  const modalCloseBtnRef2 = useRef<HTMLButtonElement>(null);
  const gridColDef: any[] = [
    {
      field: "site",
      tooltipField: "site",
      headerTooltip: "Site Name",
      cellDataType: "text",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      width: 200,
      headerName: "Site Name",

    },
    {
      field: "sku",
      tooltipField: "sku",
      headerTooltip: "SKU Code",
      cellDataType: "text",
      headerName: "SKU Code",
    },
    {
      field: "supply_id",
      tooltipField: "supply_id",
      headerTooltip: "Supply ID",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Planned Supply ID",
    },
    {
      field: "po_number",
      tooltipField: "po_number",
      headerTooltip: "PO Number",
      cellDataType: "text",
      headerName: "PO Number",
    },
    {
      field: "recommended_release_date",
      tooltipField: "recommended_release_date",
      headerTooltip: "Projected Release Date",
      cellDataType: "text",
      headerName: "Projected Release Date",
    },
    {
      field: "supply_type",
      tooltipField: "supply_type",
      headerTooltip: "Supply Type",
      cellDataType: "text",
      headerName: "Supply Type",
      width: 150,
    },
    {
      field: "expected_receipt_date",
      tooltipField: "expected_receipt_date",
      headerTooltip: "Expected Receipt Date",
      cellDataType: "text",
      headerName: "Expected Receipt Date",
    },
    
    {
      field: "quantity",
      headerTooltip: "Quantity",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Quantity",
      tooltipValueGetter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
      valueFormatter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
    },
    {
      field: "recommended_release_date",
      tooltipField: "recommended_release_date",
      headerTooltip: "Recommended Release Date",
      cellDataType: "text",
      headerName: "Recommended Release Date",
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      field: "recommended_date",
      tooltipField: "recommended_date",
      headerTooltip: "Recommended Date",
      cellDataType: "text",
      headerName: "Recommended Date",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    {
      field: "is_recommended_release_date_rejected",
      tooltipField: "is_recommended_release_date_rejected",
      headerTooltip: "Recommended Release Date Rejected",
      cellDataType: "boolean",
      headerName: "Recommended Release Date Rejected",
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: "Status",
      width: 200,
      cellRenderer: (params: any) => {
        if (
          params.data.recommended_date !== null &&
          !params.data.is_recommended_release_date_rejected
        ) {
          return (
            <div
            className="badge badge-sm bg-success text-wrap d-flex align-items-center"
            title="Recommended Date Accepted"
          >
            Recommended Date Accepted
          </div>
          );
        }
        if (
          params.data.recommended_date !== null &&
          params.data.is_recommended_release_date_rejected
        ) {
          return (
            <div
              className="badge badge-sm bg-danger text-wrap d-flex align-items-center"
              title="Recommended Date Rejected"
            >
              Recommended Date Rejected
            </div>
          );
        }
      },
    },
  ];

  const isRowSelectable = useCallback((rowNode:any) => {
    return rowNode.data ? rowNode.data.recommended_date!=null : false;
  }, []);

  // Redux variables
  const dispatchFn = useDispatch();
  const isSiteMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSiteMasterUpdated
  );
  const isPartMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isPartMasterUpdated
  );
  const tabApiDataFetched: boolean = useSelector(
    (state: RootState) => state.replenishmentTab.tabApiDataFetched
  )[PLANNED_SUPPLY_RECEIPTS_TAB_ID];
  const activeSubTabId: string = useSelector(
    (state: RootState) => state.replenishmentTab.activeSubTabId
  );
  const selectedSlicers = useSelector(
    (state: RootState) => state.replenishmentTab.selectedSlicers
  );

  const fetchPlannedSupplyReceipts = useCallback(async () => {
    const fetchingDataToastId = toast.loading(
      "Fetching planned supply receipts data...",
      {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      }
    );
    try {
      const fetchAPIResponse: AxiosResponse<
        ReplenishmentPlanTabFetchAPI<PlannedSupplyReceipt[]>
      > = await fetchData(`/planned-supply-receipts`, {
        method: "GET",
        params: {
          site_code: selectedSlicers.site_code,
          part_code: selectedSlicers.part_code,
          number_of_weeks: selectedSlicers.number_of_weeks
        },
      });

      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (fetchAPIResponse.data.data) {
        setGridRowData(fetchAPIResponse.data.data.result);
        dispatchFn(
          replenishmentTabActions.setSlicers(fetchAPIResponse.data.data.slicers)
        );
        dispatchFn(
          replenishmentTabActions.setTabApiDataFetched({
            [PLANNED_SUPPLY_RECEIPTS_TAB_ID]: true,
          })
        );
      } else {
        toast.error("Error in fetching planned supply receipts data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: Error | any) {
      console.error(`Request Error: ${error}`);
      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });
      toast.error(error?.response?.data?.error || "Error in fetching data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  }, [dispatchFn, fetchData, selectedSlicers]);

  // Initial run
  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeSubTabId === PLANNED_SUPPLY_RECEIPTS_TAB_ID
    ) {
      fetchPlannedSupplyReceipts();
    }
  }, [
    tabApiDataFetched,
    activeSubTabId,
    selectedSlicers,
    isSiteMasterUpdated,
    isPartMasterUpdated,
    fetchPlannedSupplyReceipts,
  ]);

  const rejectRecommendedDate = async (p0: boolean) => {
    try {
      const plannedSupplyReceiptId = toast.loading("Updating values...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      let idWithStatus = selectedRows.map((el)=>{
        let dict:any = {};
        dict['line_item_id'] = Object.keys(el)[0];
        dict['po_number'] = Object.values(el)[0];
        dict['is_recommended_release_date_rejected'] = p0;
        return dict;
      })


      const updateReqResponse: AxiosResponse = await fetchData(
        `/reject-recommended-release-date`,
        {
          method: "PUT",
          params: {
            site_code: selectedSlicers.site_code,
            part_code: selectedSlicers.part_code,
            number_of_weeks: selectedSlicers.number_of_weeks
          },
          data: {idWithStatus},
        }
      );

      toast.dismiss({
        id: plannedSupplyReceiptId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        toast.success(`Recommended Release Date ${p0 ? "Rejected" : "Accepted"}`, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });
        dispatchFn(replenishmentTabActions.setRefreshSummaryGrid(true));
        dispatchFn(replenishmentTabActions.setTabApiDataFetched({
          [PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false
        }));

      } else if (updateReqResponse.data.error) {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Something went wrong.", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  const downloadReports = useCallback(() => {
    const gridApi = agGridRef.current?.api;
    if (!gridApi) return;

    const originalColumnDefs = gridApi.getColumnDefs();

    // Update column definitions to hide 'Actions' column
    const updatedColumnDefs = originalColumnDefs?.map((colDef: any) => {
      if (colDef.headerName === "Status") {
        return { ...colDef, hide: true };
      }
      return colDef;
    });

    // Update grid options to apply changes
    gridApi.updateGridOptions({
      columnDefs: updatedColumnDefs,
    });

    // Retrieve all rows
    const allRows: any = [];
    gridApi.forEachNode((node: any) => allRows.push(node.data));

    // Filter rows where the status column value is 'Calculated'
    const filteredRows = allRows.filter(
      (row: any) =>
        row.is_recommended_release_date_rejected === true &&
        row.supply_type === "Reschedulable"
    );

    // Temporarily set the grid data to filteredRows for export
    gridApi.updateGridOptions({
      rowData: filteredRows,
    });

    // Export data as Excel
    gridApi.exportDataAsExcel({
      sheetName: "Filtered Data",
    });

    // Restore original data after export
    gridApi.updateGridOptions({
      rowData: gridRowData,
    });
  }, [gridRowData]);

  const onSelectionChanged = () => {
    const selectedRows1 = agGridRef.current!.api!.getSelectedRows();
    let rowIdList:any[] = selectedRows1.map((e)=> {
      let idPO:any = {}
      idPO[e.id] = e.po_number;
      return idPO
    });

    // selectedRows1.forEach((el) =>{ 
    //   let dict:any = {};
    //   dict[+el.id] = !el.is_recommended_release_date_rejected;
    //   rowList.push(dict)
    // });

    setSelectedRows(rowIdList);
  };



  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          title="Download Report"
          className="btn btn-sm btn-success mx-2"
          disabled={selectedRows.length===0}
          data-bs-toggle="modal"
          data-bs-target={`#${acceptRecommendedDateModalId}`}
        >
          Accept Recommended Date
        </button>
        <button
          className="btn btn-sm btn-danger mx-2"
          disabled={selectedRows.length===0}
          data-bs-toggle="modal"
          data-bs-target={`#${rejectRecommendedDateModalId}`}
        >
          Reject Recommended Date
        </button>
        <button
          className="btn btn-sm btn-custom-primary mx-2"
          disabled={gridRowData.length === 0 ? true : false}
          onClick={downloadReports}
        >
          <MdOutlineFileDownload /> &nbsp;Download Report
        </button>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={gridRowData}
          columnDefs={gridColDef}
          isRowSelectable={isRowSelectable}
          gridOptions={{
            rowHeight: 22,
            suppressRowHoverHighlight: true,
            enableRangeSelection: true,
            suppressMovableColumns: true,
            suppressRowDrag: true,
            clipboardDelimiter: ",",
            getContextMenuItems: (params: any) => [
              "copy",
              "copyWithHeaders",
              "excelExport",
            ],
            onSelectionChanged: onSelectionChanged,
          }}
          
          defaultColDef={{
            width: 100,
            ...(AG_GRID_DEFAULT_COL_DEF as any),
          }}
          modules={AG_GRID_MODULES}
          rowSelection={"multiple"}
        />

        {createPortal(
          <Modal
            id={rejectRecommendedDateModalId}
            ref={modalCloseBtnRef1}
            title={"Reject Recommended Date"}
            onModalClose={() => {}}
          >
            {
              <>
                
                    <p className="d-flex justify-content-center align-items-center fw-bolder">
                      Are you willing to reject recommended date?
                    </p>

                    <div className="row">
                      <div className="col col-6 mb-2 d-grid"></div>
                      <div className="col col-6 mb-2 d-grid">
                        <button
                          type="submit"
                          className="btn bg-danger text-white"
                          data-bs-dismiss="modal"
                          onClick={(e) => rejectRecommendedDate(true)}
                        >
                          <span className="fs-5 text-white">
                            <IoSendOutline />
                          </span>{" "}
                          Reject
                        </button>
                      </div>
                    </div>
                  </>
            }
          </Modal>,
          document.body
        )}

{createPortal(
          <Modal
            id={acceptRecommendedDateModalId}
            ref={modalCloseBtnRef2}
            title={"Accept Recommended Date"}
            onModalClose={() => {}}
          >
            {
              <>
              <p className="d-flex justify-content-center align-items-center fw-bolder">
                Are you willing to accept recommended date?
              </p>

              <div className="row">
                <div className="col col-6 mb-2 d-grid"></div>
                <div className="col col-6 mb-2 d-grid">
                  <button
                    type="submit"
                    className="btn bg-success text-white"
                    data-bs-dismiss="modal"
                    onClick={(e) => rejectRecommendedDate(false)}
                  >
                    <span className="fs-5 text-white">
                      <IoSendOutline />
                    </span>{" "}
                    Accept
                  </button>
                </div>
              </div>
            </>
            }
          </Modal>,
          document.body
        )}

      </div>
    </>
  );
};

export default PlannedSupplyReceiptsGrid;

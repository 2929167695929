import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useCallback, useRef, useState } from "react";
import axios, { AxiosRequestConfig, GenericAbortSignal } from "axios";
import { toast } from "react-toastify";

import { PROTECTED_RESOURCES } from "../shared/constants";

interface FetchOptions {
  method: string;
  params?: any;
  data?:any
  signal?: GenericAbortSignal;
}

interface FetchResponse {
  data: any;
  loading: boolean;
  error: string | null;
}

type UseFetchReturn = [FetchResponse, (url: string, options: FetchOptions) => Promise<any>];

const useFetch = (dataType: any = {}): UseFetchReturn => {
  const [data, setData] = useState(dataType);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { instance, accounts } = useMsal();
  const account: AccountInfo | undefined | null = useAccount(accounts[0] || {});
  const toastIdRef = useRef<any>();

  const fetchData = useCallback(async (url: string, { method, params, signal, data }: FetchOptions) => {
    if (!url) return;
    setLoading(true);

    try {
      let tokenResponse = await instance.acquireTokenSilent({scopes: PROTECTED_RESOURCES.scopes, account: account?account:undefined});
      // let toastContent = (method === "GET"?"Retrieved":method === "POST"?"Posted":method === "PUT"?"Updated":method === "DELETE"?"Deleted":"");
      try {
        const json = await callApi(url, { method, params, signal, data }, tokenResponse.accessToken);
        // toast.success(`Data ${toastContent} successfully`);
        return json;
      } catch (apiError: any) {
        setError(apiError.message || 'An error occurred');
        throw apiError;
      }

    } catch (error: any) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const tokenResponse = await instance.acquireTokenPopup({scopes: PROTECTED_RESOURCES.scopes, account: account?account:undefined});
          return await callApi(url, { method, params, signal, data }, tokenResponse.accessToken);
        } catch (popupError: any) {
          setError(popupError.message || 'An error occurred during authentication');
          instance.logout();
        }
      } else {
        setError(error.message || 'An unexpected error occurred');
      }
    } finally {
      setLoading(false);
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current);
      }
    }
  }, [account, instance]);

  const callApi = async (url: string, { method, params, signal, data = null }: FetchOptions, accessToken: string) => {
    const headers: Record<string, string> = {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    };
    const options: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_BASE_PATH}${url}`,
      method,
      headers,
      params: params,
      data: data,
      signal,
      timeout: 1_20_000
    };

    try {
      const response = await axios(options);
      if (response.status >= 400) {
        setError(response.data.error || 'An error occurred');
        throw new Error(response.data.error || 'An error occurred');
      }
      setData(response);
      return response;
    } catch (error) {
      setError('An error occurred while fetching data');
      throw error;
    }
  };

  return [{ data, loading, error }, fetchData];
};

export default useFetch;
import * as FileSaver from "file-saver";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ apiData, fileName, gridRowLength }:any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData:any, fileName:any) => {
    // Assuming the date field is named "dateField" - adjust as necessary
    apiData.forEach((item:any) => {
        const dateFields = [
            "Is Recommended Release Date",
            "Expected Receipt Date",
            "Payable Date",
            "Recommended Release",
            "Recommended Date"
        ];
    
        dateFields.forEach((field) => {
            let dateObj = item[field];
    
            if (dateObj) {
                const parsedDate = new Date(dateObj);
                item[field] = parsedDate;  // Assign the parsed date back to the item property
            }
        });
    });

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};


  return (
    <button
          title="Download Global Report"
          className="btn btn-sm btn-custom-primary mx-2"
        //   disabled={gridRowLength === 0 ? true : false}
          onClick={(e) => exportToCSV(apiData, fileName)}
        >
          <MdOutlineFileDownload /> Download Global Report
        </button>
  );
};

import { ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FaSave } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { RxCrossCircled, RxReset } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { ReplenishmentPlanTabFetchAPI } from "../../../models/APIResponses.model";
import ReplenishmentPlanSummary from "../../../models/ReplenishmentPlan/ReplenishmentPlanSummary.model";
import { ReplPlanSelectedSlicerModel } from "../../../models/ReplenishmentPlan/ReplPlanSlicer.model";
import { AG_GRID_MODULES, AG_GRID_OPTIONS, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { agGridNumberFormatter, getToastOptions } from "../../../shared/functions";
import { RootState } from "../../../store";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import "../../../styles.css";
import Modal from "../../UI/Modal/Modal";
import ReplenishmentPlanTabSlicers from "./ReplenishmentPlanSlicers";

let gridCellHighlights: { [key: string]: string[]; } = {};

const ReplenishmentPlanSummaryGrid = (props: {
    gridHeight: string;
}) => {
    // States
    const [gridRowDataCopy, setGridRowDataCopy] = useState<ReplenishmentPlanSummary[]>([]);
    const [gridColDef, setGridColDef] = useState<any[]>([]);
    const [editedColIds, setEditedColIds] = useState<any[]>([]);
    const [isFirmOrderReceiptEditable,] = useState<boolean>(false);
    const [consentModalType, setConsentModalType] = useState<"resetCellEdits" | "globalReplPlanRefresh">("resetCellEdits");
    const [consentModalResult, setConsentModalResult] = useState<boolean>(false);
    const [gridCellEdits, setGridCellEdits] = useState<{
        [key: string]: {
            [key: string]: number;
        };
    }>({});

    // Variables
    const [, fetchData] = useFetch([]);
    const agGridRef = useRef<AgGridReact>(null);
    const modalCloseBtnRef = useRef<HTMLButtonElement>(null);
    const consentModalId: string = "consentDialog";
    const rowLabelsAndIndexes: {
        [key: string]: { label: string; rowIndex: number }
    } = useMemo(() => {
        return {
            demand: {
                label: "Demand",
                rowIndex: 0,
            },
            forecast: {
                label: "Forecast",
                rowIndex: 1
            },
            order_qty: {
                label: "Customer Orders",
                rowIndex: 2
            },
            opening_inventory: {
                label: "Opening Inventory",
                rowIndex: 3,
            },
            total_projected_release_orders_qty: {
                label: "Projected Release Orders",
                rowIndex: 4,
            },
            total_firm_release_orders_qty: {
                label: "Firm Release Orders",
                rowIndex: 5,
            },
            total_receipts_qty: {
                label: "Total Order Receipts",
                rowIndex: 6,
            },
            total_planned_receipts_qty: {
                label: "Planned Receipts",
                rowIndex: 7,
            },
            total_firm_receipts_qty: {
                label: "Firm Order Receipts",
                rowIndex: 8,
            },
            total_projected_order_receipts_qty: {
                label: "Projected Order Receipts",
                rowIndex: 9,
            },
            closing_inventory: {
                label: "Closing Inventory",
                rowIndex: 10,
            },
            weeks_of_supply: {
                label: "Weeks of Supply",
                rowIndex: 11,
            },
            cuts: {
                label: "Cuts",
                rowIndex: 12,
            }
        };
    }, []);

    // Redux variables
    const dispatchFn = useDispatch();
    const gridRowData: ReplenishmentPlanSummary[] = useSelector((state: RootState) => state.replenishmentTab.summaryGridRowData);
    const selectedSlicers: ReplPlanSelectedSlicerModel = useSelector((state: RootState) => state.replenishmentTab.selectedSlicers);
    const refreshSummaryGrid: boolean = useSelector((state: RootState) => state.replenishmentTab.refreshSummaryGrid);
    const isSiteMasterUpdated = useSelector((state: RootState) => state.masterTables.isSiteMasterUpdated);
    const isPartMasterUpdated = useSelector((state: RootState) => state.masterTables.isPartMasterUpdated);
    const regeneratedFromCSR = useSelector((state:RootState) => state.replenishmentTab.regeneratedFromCSR)

    // Consent Modal body
    const consentModalBody: JSX.Element = (
        <div className="row">
            <div className="col col-12 mb-2">
                {(
                    consentModalType === "resetCellEdits" ?
                        "Do you want to reset cell edits?" :
                        "Replenishment Plan for all the site-SKU pairs will be re-generated. Do you want to proceed?"
                )}
            </div>
            <div className="col col-6">
                <button className="btn btn-warning w-100" onClick={(e) => closeConsentModal()}>
                    <span className="fs-5">
                        <RxCrossCircled />
                    </span> No
                </button>
            </div>
            <div className="col col-6">
                <button className="btn btn-success w-100" onClick={(e) => setConsentModalResult(true)}>
                    <span className="fs-5">
                        <IoIosCheckmarkCircleOutline />
                    </span> Yes
                </button>
            </div>
        </div>
    );

    /**
     * Handler to close consent modal
     */
    const closeConsentModal = () => {
        setConsentModalResult(false);
        modalCloseBtnRef.current?.click();
    };

    /**
     * Re-orders API data according to the required AG Grid row order.
     * @param {ReplenishmentPlanSummary[]} fetchApiData API data array fetched from replenishment plan summary API
     * @returns {ReplenishmentPlanSummary[]} Re-ordered API data array
     */
    const reOrderGridRows = useCallback((fetchApiData: ReplenishmentPlanSummary[]): ReplenishmentPlanSummary[] => {
        let apiSummaryData: ReplenishmentPlanSummary[] = JSON.parse(JSON.stringify(fetchApiData));

        fetchApiData.forEach((rowData) => {
            const rowIndex: number = rowLabelsAndIndexes[rowData.variable].rowIndex;
            apiSummaryData[rowIndex] = rowData;
        });

        return apiSummaryData;
    }, [rowLabelsAndIndexes]);

    /**
     * Function to handle cell's clipboard
     */
    const processCellForClipboard = useCallback((params: any) => {
        return params.value;
    }, []);

    /**
     * Handler for cell style in AG Grid ColDef
     * @param {any} params Cell Style parameters
     * @returns {any} CSS styles in JSON format
     */
    const cellStyleHandler = useCallback((params: any): any => {
        const rowLabel: string = rowLabelsAndIndexes[params.data.variable].label;

        switch (rowLabel) {
            case "Weeks of Supply":
                const hasFirmReleaseUpdates: boolean = gridCellHighlights.firm_release_updates.indexOf(params.column.colId) > -1;
                return (
                    hasFirmReleaseUpdates ? {
                        backgroundColor: "#ffff33"
                    } : {}
                );

            case "Closing Inventory":
                const isUnderSafetyStock: boolean = gridCellHighlights.below_safety_stock?.indexOf(params.column.colId) > -1;
                return (
                    isUnderSafetyStock ? {
                        backgroundColor: "#ff9999"
                    } : {}
                );
        }
    }, [rowLabelsAndIndexes]);
    
    /**
     * Returns ColDef for Replenishment Plan Summary grid
     * @param {ReplenishmentPlanSummary[]} apiData API data array fetched from replenishment plan summary API
     * @returns {any[]} Generated ColDef for Replenishment Plan Summary grid
     */
    const generateColDef = useCallback((apiData: ReplenishmentPlanSummary[]): any[] => {
        const newColDef: any[] = [
            ...Object.keys(
                (apiData && apiData.length > 0) ? apiData[0] : {}
            ).map((key) => {
                const textRegExp: RegExp = /^[a-zA-Z_]+$/g;
                const isTextKey: boolean = textRegExp.test(key);

                return {
                    field: key,
                    rowGroup: (params:any)=>{
                       return params.valueFormatted === "Firm Order Receipts"
                    },
                    cellDataType: (isTextKey) ? "text" : "number",
                    ...(
                        isTextKey ? {} : {
                            cellClass: "ag-right-aligned-cell",
                            headerClass: "ag-left-aligned-cell"
                        }
                    ),
                    ...(
                        isTextKey ? {
                            menuTabs: ["filterMenuTab"],
                            filter: "agSetColumnFilter",
                            filterParams: {
                                refreshValuesOnOpen: true,
                                valueFormatter: (params: any) => rowLabelsAndIndexes[params.value].label
                            }
                        } : {
                            suppressHeaderMenuButton: true
                        }
                    ),
                    headerName: (isTextKey) ? "Week Bucket" : key,
                    headerTooltip: (isTextKey) ? "Week Bucket" : key,
                    pinned: (isTextKey) ? "left" : null,
                    width: (isTextKey) ? 180 : 90,
                    editable: (params: any) => {
                        const formattedLabel: string = rowLabelsAndIndexes[params.node.data.variable].label;

                        return (
                            params.column.colId !== "variable" &&
                            formattedLabel === (
                                isFirmOrderReceiptEditable ?
                                    "Firm Order Receipts" :
                                    "Firm Release Orders"
                            )
                        );
                    },
                    valueFormatter: (params: any): string | null |undefined => {
                        return (
                            isTextKey ? 
                            rowLabelsAndIndexes[params.data?.[key]]?.label : 
                            agGridNumberFormatter(params.data?.[key], 2)
                        )
                    },
                    tooltipValueGetter: (params: any) => params.valueFormatted,
                    cellStyle: cellStyleHandler,
                    cellRenderer: (params: any) => {
                        const indentedText: JSX.Element = (
                            <span className="ms-4">
                                {params.valueFormatted}
                            </span>
                        );

                        if (
                            params.valueFormatted === "Firm Release Orders" ||
                            params.valueFormatted === "Firm Order Receipts"
                        ) {
                            return (
                                <div className="row">
                                    <div className="col col-10">
                                        {params.valueFormatted === "Firm Order Receipts" ? indentedText : params.valueFormatted}
                                    </div>
                                    <div className="col col-2 text-end">
                                        {(
                                            (isFirmOrderReceiptEditable && params.valueFormatted === "Firm Order Receipts") ||
                                            (!isFirmOrderReceiptEditable && params.valueFormatted === "Firm Release Orders")
                                        ) && "\u270E"}
                                    </div>
                                </div>
                            );

                        } else if (
                            params.valueFormatted === "Planned Receipts" ||
                            params.valueFormatted === "Projected Order Receipts" ||
                            params.valueFormatted === "Forecast" ||
                            params.valueFormatted === "Customer Orders"
                        ) {
                            return indentedText;
                        } else if (
                            params.valueFormatted === "Week Bucket"
                        ) {
                            return (
                                <div className="row">
                                    <div className="col col-10">
                                        {params.valueFormatted}
                                    </div>
                                    <div className="col col-2 text-end">
                                        <button className="btn btn-sm">
                                            {"\u270E"}
                                        </button>
                                    </div>
                                </div>
                            );
                        } else {
                            const rowLabel: string = rowLabelsAndIndexes[params.data.variable]?.label;

                            switch (rowLabel) {
                                case "Firm Release Orders":
                                case "Firm Order Receipts":
                                    return (params.value === null) ? null : params.valueFormatted;

                                case "Cuts":
                                    return (params.value === 0) ? "-" : params.valueFormatted;

                                default:
                                    return params.valueFormatted;
                            }
                        }
                    }
                };
            })
        ];
        return newColDef;
    }, [rowLabelsAndIndexes, isFirmOrderReceiptEditable, cellStyleHandler]);

    /**
     * Async function to fetch data from replenishment plan summary API
     */
    const fetchReplPlanSummary = useCallback(async (): Promise<void> => {
        const fetchingDataToastId = toast.loading('Fetching summary data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const fetchAPIResponse: AxiosResponse<ReplenishmentPlanTabFetchAPI<{
                summary: ReplenishmentPlanSummary[];
                cell_highlights: {
                    firm_release_updates: string[];
                    below_safety_stock: string[];
                }
            }>> = await fetchData("/replenishment-plan-summary", {
                method: "GET",
                params: {
                    site_code: selectedSlicers.site_code,
                    part_code: selectedSlicers.part_code,
                    number_of_weeks: selectedSlicers.number_of_weeks
                }
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (fetchAPIResponse.data.data) {
                const reOrderedApiData: ReplenishmentPlanSummary[] = reOrderGridRows(fetchAPIResponse.data.data.result.summary);

                dispatchFn(replenishmentTabActions.setSlicers(JSON.parse(JSON.stringify(fetchAPIResponse.data.data.slicers))));
                dispatchFn(replenishmentTabActions.setGridRowData(JSON.parse(JSON.stringify(reOrderedApiData)) as ReplenishmentPlanSummary[]));

                gridCellHighlights = { ...fetchAPIResponse.data.data.result.cell_highlights };

                setGridRowDataCopy(JSON.parse(JSON.stringify(reOrderedApiData)));
                setGridColDef((prevColDef) => generateColDef(reOrderedApiData));

            } else {
                toast.error('Error in fetching summary data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | any) {
            console.error("Error ", error)
            console.error(`Request Error: ${error}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [selectedSlicers, dispatchFn, reOrderGridRows, fetchData, generateColDef]);

    /**
     * Async function for doing global refresh of replenishment plan using its API
     */
    const globalRefreshReplPlan = useCallback(async (): Promise<void> => {
        closeConsentModal();

        const globalRefreshToastId = toast.loading('Performing global refresh of replenishment plan...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const refreshAPIResponse: AxiosResponse<ReplenishmentPlanTabFetchAPI<{
                summary: ReplenishmentPlanSummary[];
                cell_highlights: {
                    [key: string]: string[];
                }
            }>> = await fetchData(`/global-refresh-replenishment-plan`, {
                        method: "POST",
                        params: {
                            site_code: selectedSlicers.site_code,
                            part_code: selectedSlicers.part_code,
                            number_of_weeks: selectedSlicers.number_of_weeks
                        }
                    });

            toast.dismiss({
                id: globalRefreshToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (refreshAPIResponse.data.data) {
                const reOrderedApiData: ReplenishmentPlanSummary[] = reOrderGridRows(refreshAPIResponse.data.data.result.summary);

                dispatchFn(replenishmentTabActions.setSlicers(refreshAPIResponse.data.data.slicers));
                dispatchFn(replenishmentTabActions.setGridRowData(JSON.parse(JSON.stringify(reOrderedApiData))));
                dispatchFn(replenishmentTabActions.resetTabApiDataFetched(null));

                gridCellHighlights = { ...refreshAPIResponse.data.data.result.cell_highlights };

                setGridRowDataCopy(JSON.parse(JSON.stringify(reOrderedApiData)));
                setGridColDef((prevColDef) => generateColDef(reOrderedApiData));

                toast.success("Global refresh of replenishment plan completed successfully", {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("success")
                });

            } else {
                toast.error('Error in performing global refresh of replenishment plan', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | any) {
            console.error(`Request Error: ${error}`);
            toast.dismiss({
                id: globalRefreshToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in performing global refresh of replenishment plan'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [selectedSlicers, dispatchFn, reOrderGridRows, fetchData, generateColDef]);

    /**
     * Event handler for styling AG Grid rows (Demand, Total Order Receipts, Closing Inventory)
     * @param params AG Grid Row params
     * @returns {any} CSS styles in JSON form
     */
    const gridRowStyleHandler = (params: any) => {
        const rowLabel: string = rowLabelsAndIndexes[params.data.variable]?.label;

        switch(rowLabel) {
            case "Demand": 
            case "Total Order Receipts": 
            case "Closing Inventory": {
                return {
                    backgroundColor: "#D9FFD9"
                };
            }

            case "Firm Release Orders": {
                return {
                    backgroundColor: (isFirmOrderReceiptEditable) ? "#ffffff" : "#ffe0a6"
                };
            }

            case "Firm Order Receipts": {
                return {
                    backgroundColor: (isFirmOrderReceiptEditable) ? "#ffe0a6" : "#ffffff"
                };
            }

            default: {
                return { backgroundColor: "#ffffff" };
            }
        }
    };

    /**
     * Event handler for cell value change in AG Grid
     * @param {any} params Cell value change event params
     */
    const cellValueChangeHandler = (params: any): void => {
        if (params.oldValue !== params.newValue) {
            params.colDef.cellStyle = (p: any) => (
                (p.rowIndex.toString() === params.node.id) ? { backgroundColor: '#8af38a' } : {}
            );

            params.api.refreshCells({
                force: true,
                columns: [params.column.getId()],
                rowNodes: [params.node]
            });

            if (editedColIds.indexOf(params.column.getId()) === -1) {
                setEditedColIds((prev) => [...prev, params.column.getId()]);
            }

            setGridCellEdits((cellEdits) => {
                const parentKey: string = params.data.variable;
                const updatedValue: number | null = (params.newValue === null) ? params.newValue : Math.abs(params.newValue);

                if (cellEdits[parentKey]) {
                    return {
                        ...cellEdits,
                        [parentKey]: {
                            ...cellEdits[parentKey],
                            [params.column.colId]: updatedValue
                        }
                    };
                }

                const updatedCellEdits: any = {
                    ...cellEdits,
                    [parentKey]: {
                        [params.column.colId]: updatedValue
                    }
                };

                return updatedCellEdits;
            });
        }
    };

    /**
     * Removes highlight of edited cells
     */
    const removeEditedCellHighlights = useCallback((): void => {
        editedColIds.forEach((colId) => {
            agGridRef.current!.api.getColumnDef(colId)!.cellStyle = cellStyleHandler;
        });
    }, [editedColIds, cellStyleHandler]);

    /**
     * Saves cell edits of firm release orders / firm order receipts
     * @param event {any} Save button click event
     */
    const saveCellEdits = async (event: any): Promise<void> => {
        const fetchingDataToastId = toast.loading('Updating Firm Release Orders...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const updateAPIResponse: AxiosResponse<ReplenishmentPlanTabFetchAPI<{
                summary: ReplenishmentPlanSummary[];
                cell_highlights: {
                    [key: string]: string[];
                }
            }>> =
                await fetchData(`/update-firm-release-or-receipts`, {
                    method: 'PUT',
                    data: (
                        isFirmOrderReceiptEditable ?
                            gridCellEdits["total_firm_receipts_qty"] :
                            gridCellEdits["total_firm_release_orders_qty"]
                    ),
                    params: {
                        site_code: selectedSlicers.site_code,
                        part_code: selectedSlicers.part_code,
                        number_of_weeks: selectedSlicers.number_of_weeks,
                        is_firm_order_receipt_update: isFirmOrderReceiptEditable
                    }
                });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (updateAPIResponse.data.data) {
                const reOrderedApiData: ReplenishmentPlanSummary[] = reOrderGridRows(updateAPIResponse.data.data.result.summary);

                dispatchFn(replenishmentTabActions.setSlicers(updateAPIResponse.data.data.slicers));
                dispatchFn(replenishmentTabActions.setGridRowData(JSON.parse(JSON.stringify(reOrderedApiData))));
                dispatchFn(replenishmentTabActions.resetTabApiDataFetched(null));

                gridCellHighlights = { ...updateAPIResponse.data.data.result.cell_highlights };

                setGridRowDataCopy(JSON.parse(JSON.stringify(reOrderedApiData)));
                setGridColDef((prevColDef) => generateColDef(reOrderedApiData));

                setGridCellEdits({});
                removeEditedCellHighlights();

                toast.success("Firm Release Orders updated successfully", {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("success")
                });

            } else {
                toast.error('Error in updating the Firm Release Orders', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | any) {
            console.error(`Request Error: ${error}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in updating the Firm Release Orders'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    };

    /**
     * Handler to reset cell edits.
     */
    const resetCellEdits = useCallback((): void => {
        try {
            setGridCellEdits({});
            setGridRowDataCopy(JSON.parse(JSON.stringify([...gridRowData])));
            removeEditedCellHighlights();
            closeConsentModal();

        } catch (error: Error | any) {
            console.error(`Error: ${error}`);
        }
    }, [gridRowData, removeEditedCellHighlights]);

    useEffect(() => {
        fetchReplPlanSummary();
    }, [selectedSlicers, isPartMasterUpdated, isSiteMasterUpdated, regeneratedFromCSR,  fetchReplPlanSummary]);

    // Refresh grid due to firm order creation
    useEffect(() => {
        if (refreshSummaryGrid) {
            fetchReplPlanSummary();
            dispatchFn(replenishmentTabActions.setRefreshSummaryGrid(false));
        }
    }, [dispatchFn, fetchReplPlanSummary, refreshSummaryGrid]);

    // For handling consent modal
    useEffect(() => {
        if (consentModalResult) {
            if (consentModalType === "resetCellEdits") {
                resetCellEdits();
            } else {
                globalRefreshReplPlan();
            }
        }
    }, [consentModalType, consentModalResult, resetCellEdits, globalRefreshReplPlan]);

    return (
        <>
            <div className="row">
                <div className="col col-8 text-start">
                    <ReplenishmentPlanTabSlicers />
                </div>

                <div className="col col-4 text-end">
                    <div className="btn-group btn-group-sm me-1" role="group">
                        <button className="btn btn-danger border border-dark-subtle" title="Reset cell edits" onClick={(e) => setConsentModalType("resetCellEdits")} disabled={(Object.keys(gridCellEdits).length === 0)} data-bs-toggle="modal" data-bs-target={`#${consentModalId}`}>
                            <RxReset size={20} />
                        </button>

                        <button className="btn btn-success border border-dark-subtle" title="Save cell edits" disabled={(Object.keys(gridCellEdits).length === 0)} onClick={saveCellEdits}>
                            <FaSave size={20} />
                        </button>
                    </div>

                    <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={(e) => setConsentModalType("globalReplPlanRefresh")} title="Global Refresh Replenishment Plan" data-bs-toggle="modal" data-bs-target={`#${consentModalId}`}>
                        <div className="d-flex justify-content-center align-items-center">
                            <LuRefreshCcw size={20} /> &nbsp;Global Refresh
                        </div>
                    </button>
                </div>
            </div>

            <div className="ag-theme-balham mt-2" style={{ height: props.gridHeight, maxHeight: props.gridHeight, width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                <AgGridReact
                    ref={agGridRef}
                    rowData={gridRowDataCopy}
                    columnDefs={gridColDef}
                    gridOptions={{
                        ...(AG_GRID_OPTIONS as any),
                        suppressMenuHide: true,
                        getRowStyle: gridRowStyleHandler
                    }}
                    defaultColDef={{
                        sortable: false
                    }}
                    modules={[
                        ...AG_GRID_MODULES,
                        ColumnsToolPanelModule,
                        FiltersToolPanelModule,
                        SetFilterModule
                    ]}
                    onCellValueChanged={cellValueChangeHandler}
                    processCellForClipboard={processCellForClipboard}
                />
            </div>

            {
                createPortal(
                    <Modal
                        id={consentModalId}
                        ref={modalCloseBtnRef}
                        title={(
                            consentModalType === "resetCellEdits" ? "Reset Cell Edits" : "Global Refresh"
                        )}
                        onModalClose={() => { }}
                    >
                        {consentModalBody}
                    </Modal>,
                    document.body
                )
            }
        </>
    );
};

export default ReplenishmentPlanSummaryGrid;

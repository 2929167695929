import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetchMSAL";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AG_GRID_MODULES, AG_GRID_OPTIONS, TOAST_CONTAINER_ID, WEEKS_OF_SUPPLY_ID } from "../../../shared/constants";
import { agGridNumberFormatter, getToastOptions } from "../../../shared/functions";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../../../store";
import { controllTowerTabActions } from "../../../store/slices/control-tower-tab-slice";
import { ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule, ValueFormatterParams } from "ag-grid-enterprise";

const WeeksOfSupplyGrid = (props:{
    gridHeight:string
}) => {

    const [{ data, loading, error }, fetchData] = useFetch([]);

    const agGridRef = useRef<AgGridReact>(null);
    const [gridRowDataCopy, setGridRowDataCopy] = useState<[]>([]);
    const [gridColDef, setGridColDef] = useState<any>([]);
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.controllTowerTab.tabApiDataFetched)[WEEKS_OF_SUPPLY_ID];
    const activeUpperSubTabId: string = useSelector((state: RootState) => state.controllTowerTab.activeSubTabId.lower);
    const dispatchFn = useDispatch();

    const selectedSlicers = useSelector((state: RootState) => state.controllTowerTab.selectedGridSlicers);
    const applyFilter = useSelector((state: RootState) => state.controllTowerTab.applyFilter);

    const colNames = (el:string) => {
        if(el === "part_name")
            return "Part Name"
        else if(el === "site_name")
            return "Site Name"
        else if(el === "weeks_of_supply")
            return "Weeks Of Supply"
    }

    function formatNumber(params:any) {
        // Check if the column is "Weeks Of Supply"
        if (params.colDef.headerName === "Weeks Of Supply") {
            if (params.value){
                // Check if the value is null or undefined
                if (params.value.value === null || params.value.value === undefined) {
                    return null; // Return a blank string for null/undefined
                }
                // Format the number to two decimal places
                return Number(params.value).toFixed(2);
            }
        } else {
            return params.value; // Return the value for other columns
        }
    }


    const generateColDef = (rowData:any) =>{
        const coldefs:any = [];
        
        Object.keys(rowData).forEach(e =>{
            let obj:any = {
                headerName: colNames(e),
                cellDataType: e === 'week_bucket' ? 'text' : e==='weeks_of_supply'?'number':'text',
                field: e,
                sortable: false,
                rowGroup: e === 'part_name'||e === 'site_name' ? true:false,
                hide: e === 'part_name'||e === 'site_name' ? true:false,
                pinned: e === 'part_name'||e === 'site_name' ? "left":"",
                pivot: e === 'week_bucket'? true:false,
                aggFunc: e === 'weeks_of_supply' ? "avg": "",
                cellClass: (e === "weeks_of_supply") ? "ag-right-aligned-cell" : "ag-left-aligned-cell",
                headerClass: "ag-left-aligned-cell",
                cellStyle: (p:any) => {
                    return (
                    {
                        backgroundColor: p.value.value<2 && p.value.value>0 ? "#5f5aa2" : p.value.value === 0 ? "#E9EB86" : (p.value.value>12 ? "#db5461" :'white'),
                        color: p.value.value<2 && p.value.value>0 ? "white" : (p.value.value>12 ? "white":'black')
                      }
                )},
                cellRenderer: formatNumber,
                width: 180,
            }

            if(e === 'week_bucket'){
                obj['pivotComparator'] = (valueA:any, valueB:any) => {
                    const dateA:any = new Date(valueA);
                    const dateB:any = new Date(valueB);

                    return dateA - dateB; // Sort in ascending order
                  }
            }
            coldefs.push(obj)
        });
        return coldefs;
    }

    /**
     * Async function to fetch data from replenishment plan summary API
     */
    const fetchWeeksOfSupply = useCallback(async (): Promise<void> => {
        const fetchingDataToastId = toast.loading('Fetching weeks of supply grid data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const fetchAPIResponse: AxiosResponse = await fetchData("/get-weeks-of-supply-control-tower", {
                method: "POST",
                data: {
                    site_code: selectedSlicers.location_names,
                    part_code: selectedSlicers.part_names,
                    number_of_weeks: selectedSlicers.number_of_weeks
                }
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (fetchAPIResponse.data.data) {
                if(fetchAPIResponse.data.data.length > 0){
                    console.log(fetchAPIResponse.data.data)
                    dispatchFn(controllTowerTabActions.setGridRowData(JSON.parse(JSON.stringify(fetchAPIResponse.data.data))));
                    setGridRowDataCopy(JSON.parse(JSON.stringify(fetchAPIResponse.data.data)));
                    setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify(fetchAPIResponse.data.data[0]))));
                }else{
                    dispatchFn(controllTowerTabActions.setGridRowData([]));
                    setGridRowDataCopy([]);
                    setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify({
                        "week_bucket": 1719100800000,
                        "closing_inventory": 7789,
                        "part_name": "CT-01",
                        "site_name": "Evobox East_1",
                        "demand": 129.78,
                        "weeks_of_supply": 2749.5
                    }))));
                }
                dispatchFn(
                    controllTowerTabActions.setTabApiDataFetched({
                      [WEEKS_OF_SUPPLY_ID]: true,
                    })
                  );

            } else {
                toast.error('Error in fetching summary data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | AxiosError | any) {
            console.error("Error ", error)
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [selectedSlicers, TOAST_CONTAINER_ID, fetchData]);

    useEffect(()=>{
        if (
            !tabApiDataFetched &&
            activeUpperSubTabId === WEEKS_OF_SUPPLY_ID
          ) {
        fetchWeeksOfSupply();
    }
    },[applyFilter, tabApiDataFetched, activeUpperSubTabId, fetchWeeksOfSupply])

    return (
        <>
            <div
          className="ag-theme-balham mt-2"
          style={{
            height: props.gridHeight,
            maxHeight: props.gridHeight,
            width: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <AgGridReact
                    ref={agGridRef}
                    rowData={gridRowDataCopy}
                    columnDefs={gridColDef}
                    groupHideOpenParents={true}
                    gridOptions={{
                        ...(AG_GRID_OPTIONS as any),
                        suppressMenuHide: true,
                        suppressAggFuncInHeader: true
                    }}
                    defaultColDef={{
                        sortable: false
                    }}
                    modules={[
                        ...AG_GRID_MODULES,
                        ColumnsToolPanelModule,
                        FiltersToolPanelModule,
                        SetFilterModule
                    ]}
                    autoGroupColumnDef={{
                        cellRendererParams: {
                            suppressCount: true
                        }
                    }}
                    pivotMode={true}
                    animateRows={true}
                    groupDisplayType="multipleColumns"
                    
                    // onCellValueChanged={cellValueChangeHandler}
                    // processCellForClipboard={processCellForClipboard}
                />
        </div>
        </>
    );
}
export default WeeksOfSupplyGrid
import "ag-grid-enterprise";
import {
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  LicenseManager,
  MenuModule,
  ModuleRegistry,
  RangeSelectionModule,
  SetFilterModule
} from 'ag-grid-enterprise';
import ReactDOM from 'react-dom/client';
// import { AgCharts } from 'ag-charts-enterprise';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MSAL_CONFIG } from './shared/constants';

const msalInstance = new PublicClientApplication(MSAL_CONFIG);
msalInstance.initialize();

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);
// AgCharts.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);

ModuleRegistry.registerModules([
  RangeSelectionModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  MenuModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  SetFilterModule
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
);

reportWebVitals();

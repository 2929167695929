import { createSlice, Reducer, Slice } from "@reduxjs/toolkit";

import ReplenishmentTabSlice from "../../models/Redux/ReplenishmentTabSlice.model";
import { ReplPlanAPISlicerModel, ReplPlanSelectedSlicerModel, ReplPlanSlicerDropdownModel } from "../../models/ReplenishmentPlan/ReplPlanSlicer.model";
import { getDefaultReplPlanSlicers } from "../../shared/functions";
import { BUY_PLAN_TAB_ID, CALCULATED_SUPPLY_RECEIPTS_TAB_ID, DEMAND_PEGGING_TAB_ID, PLANNED_SUPPLY_RECEIPTS_TAB_ID, S_AND_OP_CHARTS_TAB_ID } from "../../shared/constants";
import ReplenishmentPlanSummary from "../../models/ReplenishmentPlan/ReplenishmentPlanSummary.model";

const defaultReplPlanSlicers: ReplPlanSelectedSlicerModel = getDefaultReplPlanSlicers();

const initialState: ReplenishmentTabSlice = {
    summaryGridRowData: [],
    activeSubTabId: S_AND_OP_CHARTS_TAB_ID,
    tempSelectedSlicers: defaultReplPlanSlicers,
    selectedSlicers: defaultReplPlanSlicers,
    isReplenishmentPlanTabEditing: false,
    refreshSummaryGrid: false,
    tabApiDataFetched: {
        [DEMAND_PEGGING_TAB_ID]: false,
        [PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false,
        [CALCULATED_SUPPLY_RECEIPTS_TAB_ID]: false,
        [BUY_PLAN_TAB_ID]: false
    },
    slicers: {
        site_codes: [],
        part_codes: [],
        mappings: {},
        number_of_weeks: 0
    },
    regeneratedFromCSR: false
};

const replenishmentTabSlice: Slice = createSlice({
    name: "replenishmentTab",
    initialState,
    reducers: {
        setSlicers: (state: ReplenishmentTabSlice, action: { payload: ReplPlanAPISlicerModel }) => {
            const tempSlicers: ReplPlanSlicerDropdownModel = {
                site_codes: [],
                part_codes: [],
                mappings: {},
                number_of_weeks: 0
            };

            // Get site slicers
            tempSlicers.site_codes = action.payload.sites.map((site) => ({
                name: site.location_display_name,
                value: site.location_code
            }));

            // Get slicer mappings
            tempSlicers.mappings = {};

            for (let key in action.payload.mappings) {
                tempSlicers.mappings[key] = action.payload.mappings[key].map((part_details) => ({
                    name: part_details.part_display_name,
                    value: part_details.part_code
                }));
            }

            // Get part slicers
            tempSlicers.part_codes = tempSlicers.mappings[state.selectedSlicers.site_code];

            // Number of weeks
            tempSlicers.number_of_weeks = action.payload.weeks;

            state.slicers = tempSlicers;
        },
        setGridRowData(state, action: { payload: ReplenishmentPlanSummary[] }) {
            state.summaryGridRowData = action.payload;
        },
        setActiveSubTabId: (state, action: { payload: string }) => {
            state.activeSubTabId = action.payload;
        },
        setTabApiDataFetched: (state, action: { payload: { [key: string]: boolean; } }) => {
            state.tabApiDataFetched = {
                ...state.tabApiDataFetched,
                ...action.payload
            };
        },
        setPartSlicer: (state, action: { payload: string }) => {
            state.slicers.part_codes = state.slicers.mappings[action.payload];
            state.tempSelectedSlicers.part_code = state.slicers.part_codes[0].value;
        },
        setRefreshSummaryGrid: (state, action: { payload: boolean }) => {
            state.refreshSummaryGrid = action.payload;
        },
        setTempSlicers: (state, action: { payload: ReplPlanSelectedSlicerModel }) => {
            state.tempSelectedSlicers = action.payload;
        },
        setSelectedSlicers: (state, action) => {
            state.selectedSlicers = state.tempSelectedSlicers;
        },
        setIsReplenishmentPlanTabEditing: (state, action: { payload: boolean }) => {
            state.isReplenishmentPlanTabEditing = action.payload;
        },
        resetSelectedSlicers: (state, action) => {
            const defaultSlicers: ReplPlanSelectedSlicerModel = getDefaultReplPlanSlicers();
            state.tempSelectedSlicers = JSON.parse(JSON.stringify(defaultSlicers));
            state.selectedSlicers = JSON.parse(JSON.stringify(defaultSlicers));
        },
        resetTabApiDataFetched: (state, action) => {
            state.tabApiDataFetched = {
                [DEMAND_PEGGING_TAB_ID]: false,
                [PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false,
                [CALCULATED_SUPPLY_RECEIPTS_TAB_ID]: false,
                [BUY_PLAN_TAB_ID]: false
            };
        },
        setRegeneratedFromCSR: (state, action) =>{
            state.regeneratedFromCSR = action.payload;
        }
    }
});

export const replenishmentTabActions = replenishmentTabSlice.actions;

export default replenishmentTabSlice.reducer as Reducer<ReplenishmentTabSlice>;

import { useState } from "react";

const useInput = (validate: Function) => {
    const [enteredValue, setEnteredValue] = useState<any>("");
    const [enteredValueIsTouched, setEnteredValueIsTouched] = useState<boolean>(false);

    const valueIsValid: boolean = validate(enteredValue);
    const valueHasError: boolean = !valueIsValid && enteredValueIsTouched;

    const valueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnteredValue(event.target.value);
    };

    const valueBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setEnteredValueIsTouched(true);
    };

    const resetEnteredValue = () => {
        setEnteredValue("");
        setEnteredValueIsTouched(false);
    };

    return {
        value: enteredValue,
        valueIsValid,
        valueHasError,
        valueChangeHandler,
        valueBlurHandler,
        resetEnteredValue
    };
};

export default useInput;

import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowDropup, IoIosCloseCircleOutline } from "react-icons/io";
import { FaWeightScale } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { ReactElement, useEffect, useRef, useState } from "react";
import { LuNewspaper, LuFileSpreadsheet, LuSettings2 } from "react-icons/lu";
import { FaBroadcastTower, FaRegCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticatedTemplate, useAccount, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { RiAccountCircleFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import "../../styles.css";
import "react-toastify/dist/ReactToastify.css";
import { getDefaultLSOSlicers, getDefaultReplPlanSlicers, getToastOptions } from "../../shared/functions";
import PlanningParametersTab from "./PlanningParameters/PlanningParametersTab";
import ReplenishmentPlanTab from "./ReplenishmentPlanTab/ReplenishmentPlanTab";
import planVidaLogo from "../../assets/images/planvida-logo-no-bg.png";
import {
  CONTROL_TOWER_TAB_ID,
  DEMAND_PLANNING_TAB_ID,
  MASTER_TABLES_TAB_ID,
  PLANNING_PARAMETERS_TAB_ID,
  REPLENISHMENT_PLAN_TAB_ID,
  TOAST_CONTAINER_ID,
  LOT_SIZE_OPTIMIZATION_TAB_ID,
  LSO_S_AND_OP_CHARTS_TAB_ID
} from "../../shared/constants";
import DemandPlanningTab from "./DemandPlanningTab/DemandPlanningTab";
import MasterTablesTab from "./MasterTablesTab/MasterTablesTab";
import { RootState } from "../../store";
import { replenishmentTabActions } from "../../store/slices/replenishment-tab-slice";
import { planningParametersTabActions } from "../../store/slices/planning-parameters-tab-slice";
import { masterTablesTabActions } from "../../store/slices/master-tables-tab-slice";
import LotSizeOptimizationTab from "./LotSizeOptimizationTab/LotSizeOptimizationTab";
import { lotSizeOptimizationTabActions } from "../../store/slices/lot-size-optimization-tab-slice";
import { LSOSelectedSlicerModel } from "../../models/LotSizeOptimization/LSOSlicer.model";
import { ReplPlanSelectedSlicerModel } from "../../models/ReplenishmentPlan/ReplPlanSlicer.model";
import ControlTowerTab from "./ControlTowerTab/ControlTowerTab";
import { controllTowerTabActions } from "../../store/slices/control-tower-tab-slice";


interface TabDetail {
  id: string;
  name: string;
  content: ReactElement;
  icon: ReactElement;
}


const Home = () => {

  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const account = useAccount(accounts[0] || {});

  // States
  const [openTabIds, setOpenTabIds] = useState<string[]>([REPLENISHMENT_PLAN_TAB_ID]);
  const [isNavSidebarExpanded, setIsNavSidebarExpanded] = useState<boolean>(false);
  const [activeTabId, setActiveTabId] = useState<string>(REPLENISHMENT_PLAN_TAB_ID);

  // Variables
  const dispatchFn = useDispatch();

  // References
  const sidebarRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  // Variables
  const allTabs: TabDetail[] = [
    {
      id: REPLENISHMENT_PLAN_TAB_ID,
      name: "Replenishment Plan",
      content: <ReplenishmentPlanTab />,
      icon: <FaRegCalendarAlt />,
    },
    {
      id: LOT_SIZE_OPTIMIZATION_TAB_ID,
      name: "Lot Size Optimization",
      content: <LotSizeOptimizationTab />,
      icon: <FaWeightScale />
    },
    {
      id: PLANNING_PARAMETERS_TAB_ID,
      name: "Planning Parameters",
      content: <PlanningParametersTab />,
      icon: <LuNewspaper />,
    },
    {
      id: DEMAND_PLANNING_TAB_ID,
      name: "Demand Planning",
      content: <DemandPlanningTab />,
      icon: <LuFileSpreadsheet />,
    },
    {
      id: MASTER_TABLES_TAB_ID,
      name: "Master Tables",
      content: <MasterTablesTab />,
      icon: <LuSettings2 />,
    },
    {
      id: CONTROL_TOWER_TAB_ID,
      name: "Control Tower",
      content: <ControlTowerTab/>,
      icon: <FaBroadcastTower />
    }
  ];
  const allTabIds: string[] = allTabs.map(tab => tab.id);

  // Variables
  const tabDetailMappings: {
    [key: string]: { [key: string]: any; }
  } = {
    name: {
      [REPLENISHMENT_PLAN_TAB_ID]: "Replenishment Plan",
      [LOT_SIZE_OPTIMIZATION_TAB_ID]: "Lot Size Optimization",
      [PLANNING_PARAMETERS_TAB_ID]: "Planning Parameters",
      [DEMAND_PLANNING_TAB_ID]: "Demand Planning",
      [MASTER_TABLES_TAB_ID]: "Master Tables",
      [CONTROL_TOWER_TAB_ID]: "Control Tower"
    },
    content: {
      [REPLENISHMENT_PLAN_TAB_ID]: <ReplenishmentPlanTab />,
      [LOT_SIZE_OPTIMIZATION_TAB_ID]: <LotSizeOptimizationTab />,
      [PLANNING_PARAMETERS_TAB_ID]: <PlanningParametersTab />,
      [DEMAND_PLANNING_TAB_ID]: <DemandPlanningTab />,
      [MASTER_TABLES_TAB_ID]: <MasterTablesTab />,
      [CONTROL_TOWER_TAB_ID]: <ControlTowerTab/>
    },
    icon: {
      [REPLENISHMENT_PLAN_TAB_ID]: <FaRegCalendarAlt />,
      [LOT_SIZE_OPTIMIZATION_TAB_ID]: <FaWeightScale />,
      [PLANNING_PARAMETERS_TAB_ID]: <LuNewspaper />,
      [DEMAND_PLANNING_TAB_ID]: <LuFileSpreadsheet />,
      [MASTER_TABLES_TAB_ID]: <LuSettings2 />,
      [CONTROL_TOWER_TAB_ID]: <FaBroadcastTower />
    },
  };

  const isPlanningParametersTabEdited = useSelector(
    (state: RootState) =>
      state.planningParametersTab.isPlanningParametersTabEdited
  );

  const isMasterTablesEdited = useSelector(
    (state: RootState) => state.masterTables.isMasterTablesEdited
  );

  const planningParamSlicers = {
    part_name: ["All"],
    source_value: ["All"],
    location_name: ["All"],
  }

  // Function to handle tab click
  const onTabClick = (tabId: string) => {
    setActiveTabId(tabId);
  };

  // Function to open a tab by it's ID
  const onOpenTab = (tabId: string): void => {
    const isTabOpen: boolean = openTabIds.indexOf(tabId) > -1;

    if (isTabOpen) {
      setActiveTabId(tabId);
    } else {
      setActiveTabId(tabId);
      setOpenTabIds((openTabIds) => openTabIds.concat(tabId));
    }
  };

  // Function to close a tab by it's ID
  const onCloseTab = (index: number): void => {
    if (openTabIds.length === 1) {
      toast.warning("At least 1 tab needs to be active.", {
        ...getToastOptions("warning"),
        autoClose: 1500,
      });
    } else {
      const tabIdToClose: string = openTabIds[index];

      switch (tabIdToClose) {
        case REPLENISHMENT_PLAN_TAB_ID: {
          const defaultReplPlanSlicers: ReplPlanSelectedSlicerModel = getDefaultReplPlanSlicers();

          dispatchFn(replenishmentTabActions.resetTabApiDataFetched(null));
          dispatchFn(replenishmentTabActions.setTempSlicers(defaultReplPlanSlicers));
          break;
        }
        case LOT_SIZE_OPTIMIZATION_TAB_ID: {
          const defaultLSOSlicers: LSOSelectedSlicerModel = getDefaultLSOSlicers();

          dispatchFn(lotSizeOptimizationTabActions.resetTabApiDataFetched(null));
          dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers(defaultLSOSlicers));
          dispatchFn(lotSizeOptimizationTabActions.setActiveSubTabId(LSO_S_AND_OP_CHARTS_TAB_ID));
          break;
        }
        case PLANNING_PARAMETERS_TAB_ID: {
          dispatchFn(planningParametersTabActions.resetTabApiDataFetched(null));

          dispatchFn(planningParametersTabActions.setSelectedGridSlicers({
            part_name: ["All"],
            source_value: ["All"],
            location_name: ["All"]
          }));
          dispatchFn(planningParametersTabActions.setTempSelectedSlicers({
            part_name: ["All"],
            source_value: ["All"],
            location_name: ["All"]
          }));
          break;
        }
        case MASTER_TABLES_TAB_ID: {
          dispatchFn(masterTablesTabActions.resetTabApiDataFetched(null));
          break;
        }
        case CONTROL_TOWER_TAB_ID: {
          dispatchFn(controllTowerTabActions.resetTabApiDataFetched(null));
          dispatchFn(controllTowerTabActions.setSelectedGridSlicers({
                part_names: ["All"],
                location_names: ["All"],
                number_of_weeks: 25
              }));
          dispatchFn(controllTowerTabActions.setTempSelectedSlicers({
            part_names: ["All"],
                location_names: ["All"],
                number_of_weeks: 25
          }));
          break;
        }
      }

      if (activeTabId === tabIdToClose) {
        const newIdx: number = index === 0 ? index + 1 : index - 1;
        setActiveTabId(openTabIds[newIdx]);
      }

      setOpenTabIds((openTabIds) =>
        openTabIds.filter((tabId) => tabId !== tabIdToClose)
      );
    }
  };

  // Function to expand sidebar
  const toggleSidebar = (isSidebarExpanded: boolean) => {
    const maxSidebarWidth: number =
      Math.max(
        ...allTabIds.map((tabId) => tabDetailMappings.name[tabId].length)
      ) * 14;
    (sidebarRef.current as HTMLDivElement).style.width = isSidebarExpanded
      ? "50px"
      : `${maxSidebarWidth}px`;
    (mainRef.current as HTMLDivElement).style.marginLeft = isSidebarExpanded
      ? "40px"
      : `${maxSidebarWidth - 10}px`;
    setIsNavSidebarExpanded(!isSidebarExpanded);
  };

  const tabNameChanges = (tabName: any) => {

    if (tabName === "Planning Parameters") {
      return Object.values(isPlanningParametersTabEdited).includes(true)
        ? `${tabName} *`
        : tabName;
    } else if (tabName === "Master Tables") {
      return Object.values(isMasterTablesEdited).includes(true)
        ? `${tabName} *`
        : tabName;
    } else {
      return tabName;
    }
  };

  useEffect(() => {
    if (openTabIds.length === 1) {
      setActiveTabId(openTabIds[0]);
    }
  }, [openTabIds]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);


  const handleLogout = async () => {
    instance.logout().then(() => {
      navigate('/login')
    }
    );
  }

  return (
    <AuthenticatedTemplate>
      <div className="container-fluid">
        <div ref={sidebarRef} id="custom-sidebar" className="sidebar shadow shadow-1">
          <div className="d-flex mx-2 mb-4">
            <div
              role="button"
              className="btn btn-clear ms-1 sidebar-icon fs-5 custom-primary-text"
              onClick={(e) => toggleSidebar(isNavSidebarExpanded)}
              title="Toggle sidebar"
            >
              <GiHamburgerMenu />
            </div>
            <div className="sidebar-icon-text" title="Demand Planning LLC">
              <img
                src={planVidaLogo}
                alt="Planvida_logo"
                className="sidebar-logo"
              />
            </div>
          </div>

          {allTabIds.map((tabId, idx) => (
            <div
              role="button"
              className={
                "btn mx-2 mb-2 custom-primary-text" +
                (openTabIds.indexOf(tabId) > -1
                  ? activeTabId === tabId
                    ? "btn-outline-primary bg-primary-subtle fw-bold custom-border-top custom-border-right custom-border-bottom custom-border-left"
                    : "btn-outline-primary fw-bold custom-border-top custom-border-right custom-border-bottom custom-border-left"
                  : "")
              }
              key={`sidebar-btn-${idx}`}
              title={tabDetailMappings.name[tabId]}
              onClick={(e) => onOpenTab(tabId)}
            >
              <div className="sidebar-icon fs-5">
                {tabDetailMappings.icon[tabId]}
              </div>
              <div className="sidebar-icon-text fs-6 mx-2">
                {tabDetailMappings.name[tabId]}
              </div>
            </div>
          ))}

          <div className="mb-5 py-2" style={{ position: 'absolute', bottom: '0', width: '200px' }}>
            <div className="mx-1 btn-group dropup" style={{ width: '250px' }}>
              <button type="button" className="btn btn-outline-dark" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="d-flex align-items-center ">
                  <div className="sidebar-icon fs-3">
                    <RiAccountCircleFill />
                  </div>
                  <div className="sidebar-icon-text fs-6 mx-3">
                    {account?.name}
                  </div>
                  <div className="mb-2"><IoIosArrowDropup /></div>
                </div>
              </button>
              <ul className="dropdown-menu w-100 bg-danger">
                <div className="d-flex align-items-center bg-danger text-white">
                  <div className="sidebar-icon fs-5 mx-3">
                    <FiLogOut />
                  </div>
                  <li className="text-center fs-6 mx-3 " onClick={handleLogout}>Sign Out</li>
                </div>
              </ul>
            </div>

          </div>
        </div>

        <div ref={mainRef} id="main">
          <ToastContainer containerId={TOAST_CONTAINER_ID} position="top-right" />

          <ul className="nav nav-tabs">
            {openTabIds.map((tabId, idx) => (
              <div
                className={`d-flex align-items-center justify-content-center rounded-top border-primary border-start border-top border-end ${activeTabId === tabId
                    ? "bg-primary-subtle"
                    : "border-bottom opacity-50 bg-secondary-subtle"
                  }`}
                key={`nav-tab-${idx}`}
              >
                <li className="nav-item">
                  <a
                    id={`${tabId}-link`}
                    href={`#${tabId}`}
                    role="button"
                    data-bs-toggle="tab"
                    className={`nav-link ${activeTabId === tabId ? "bg-primary-subtle" : ""
                      }`}
                    onClick={(e) => onTabClick(tabId)}
                  >
                    <b className="fs-6 custom-primary-text">
                      {tabDetailMappings.icon[tabId]} {tabNameChanges(tabDetailMappings.name[tabId])}
                    </b>
                  </a>
                </li>

                {openTabIds.length > 1 && (
                  <li className="nav-item">
                    <a
                      href="#"
                      className={`nav-link`}
                      role="button"
                      data-bs-toggle="tab"
                      onClick={(e) => onCloseTab(idx)}
                    >
                      <span className="fs-6 text-danger">
                        <IoIosCloseCircleOutline />
                      </span>
                    </a>
                  </li>
                )}
              </div>
            ))}
          </ul>

          <div className="tab-content mt-2">
            {allTabs.map((tab, idx) => (
              <div
                className={`tab-pane ${activeTabId === tab.id ? "active" : ""}`}
                id={tab.id}
                key={`tab-content-${idx}`}
              >
                {(openTabIds.indexOf(tab.id) > -1) && tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>




    </AuthenticatedTemplate>
  );
};

export default Home;

import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { SafetyStockPolicyFetchAPI } from "../../../models/APIResponses.model";
import CellEditTrack from "../../../models/CellEditTrack.model";
import SafetyStockPolicyModel from "../../../models/PlanningParameters/SafetyStockPolicy.model";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID,
  PART_SITE_SOURCE_TAB_ID,
  SAFETY_STOCK_POLICY_TAB_ID,
  TIME_PHASED_SAFETY_STOCK_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import { getToastOptions, newGridRowStyleHandler, removeInsertedRowsById } from "../../../shared/functions";
import { RootState } from "../../../store";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import "../../../styles.css";
import EditableGridActions from "../../UI/EditableGridActions/EditableGridActions";

const SafetyStockPolicyTable = (props: {
  gridHeight: string;
}) => {
  const [, fetchData] = useFetch([]);

  // States
  const [agRowData, setAgRowData] = useState<SafetyStockPolicyModel[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<SafetyStockPolicyModel[]>(
    []
  );
  const [cellEditTracks, setCellEditTracks] = useState<{
    [key: string]: { [key: string]: CellEditTrack };
  }>({});
  const [changeTrackState, setChangeTrackState] = useState(false);
  // const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [enableDelButton, setEnableDelButton] = useState(false);
  const [, setSafetyStockPolicyValues] = useState<string[]>([]);
  const [safetyStockRuleValues, setSafetyStockRuleValues] = useState<string[]>(
    []
  );

  // Redux variables
  const selectedGridSlicers = useSelector(
    (state: RootState) => state.planningParametersTab.selectedGridSlicers
  );
  const isSafetyStockPolicyUpdated = useSelector(
    (state: RootState) => state.planningParametersTab.isSafetyStockPolicyUpdated
  );
  const activeLowerSubTabId: string = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId.lower);
  const tabApiDataFetched: boolean = useSelector((state: RootState) => state.planningParametersTab.tabApiDataFetched)[SAFETY_STOCK_POLICY_TAB_ID];
  const dispatch = useDispatch();

  // Variables
  const agGridRef = useRef<AgGridReact>(null);

  const ColDef: any[] = [
    // {field: 'id'},
    {
      headerName: "Safety Stock Policy",
      field: "safety_stock_policy",
      cellDataType: "text",
      cellEditor: "agTextCellEditor",
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Safety Stock Rule",
      field: "safety_stock_rule",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      editable: true,
      cellEditorParams: { values: safetyStockRuleValues },
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      ...(AG_GRID_DEFAULT_COL_DEF as any),
    };
  }, []);

  /**
   * Fetches safety stock policy data from API
   * @param {any} body Request body
   */
  const fetchSafetyStockPolicy = useCallback(
    async (body: any): Promise<void> => {
      setChangeTrackState(false);
      const fetchingDataToastId = toast.loading("Fetching Safety Stock Policy data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });
      try {
        const sspFetchAPIResponse: AxiosResponse<SafetyStockPolicyFetchAPI> =
          await fetchData(
            `/get-safety-stock-policy`,
            {
              method: 'POST',
              data: {}
            }
          );

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (sspFetchAPIResponse.data.data) {
          setAgRowData(sspFetchAPIResponse.data.data);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(sspFetchAPIResponse.data.data))
          );
          setSafetyStockRuleValues(
            sspFetchAPIResponse.data.cell_dropdowns.safety_stock_rule
          );
          setSafetyStockPolicyValues(
            sspFetchAPIResponse.data.safety_stock_policy
          );
          dispatch(planningParametersTabActions.setTabApiDataFetched({
            [SAFETY_STOCK_POLICY_TAB_ID]: true
          }));

        } else {
          toast.error("Error in fetching Safety Stock Policy data", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching safety stock policy data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [dispatch, fetchData]
  );

  /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
  const onCellEditingStoppedHandler = (params: any) => {
    try {
      const id = params.node.data.id;
      const colName = params.column.colId;

      if (id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};

          newRow[colName] = params.newValue;
          newData[id] = newRow;
          return newData;
        });
      } else {
        if (
          params.oldValue !== params.newValue &&
          params.newValue !== undefined
        ) {
          let currentRowNode = agGridRef.current!.api!.getRowNode(
            params.node.id
          );

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

            cellEditTrack[colName] = params.newValue;

            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });

          dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'safetyStockPolicy', value: true }));

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        } else if (params.newValue === params.oldValue) {
          dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'safetyStockPolicy', value: false }));
        }
      }
    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Sets selected rows on selection checkbox change
   */
  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
  const deleteSelectedRowData = async (selectedRowIds: any[]) => {
    let deleteRowsToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteRowsToastId = toast.loading("Deleting selected rows...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse =
          await fetchData(
            `/delete-safety-stock-policy`,
            {
              method: 'DELETE',
              data: { row_ids_to_delete: selectedRowIds },
            }
          );

        toast.dismiss({
          id: deleteRowsToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.success) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success("Data updated successfully", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });
          dispatch(planningParametersTabActions.setIsSafetyStockPolicyUpdated(!isSafetyStockPolicyUpdated));

          dispatch(planningParametersTabActions.setTabApiDataFetched({
            [TIME_PHASED_SAFETY_STOCK_TAB_ID]: false
          }));

          dispatch(planningParametersTabActions.setTabApiDataFetched({
            [SAFETY_STOCK_POLICY_TAB_ID]: false
          }));

        } else {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Failed to delete the selected rows", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Updates cell values by using part site source update API
   * @param {any} cellEditTracks Cell edits in the grid
   * @param {any} insertedRows Newly inserted rows
   */
  const updateCellValues = async (
    cellEditTracks: any,
    insertedRows: any
  ): Promise<void> => {
    try {
      const updateToastId = toast.loading("Updating Safety Stock Policy data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse =
        await fetchData(
          `/update-safety-stock-policy`,
          {
            method: 'PUT',
            data: {
              updated_data: cellEditTracks,
              inserted_data: insertedRows,
            }
          }
        );

      toast.dismiss({
        id: updateToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        setCellEditTracks({});
        setInsertedRows({});

        dispatch(planningParametersTabActions.setIsSafetyStockPolicyUpdated(!isSafetyStockPolicyUpdated));
        dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'safetyStockPolicy', value: false }));
        dispatch(planningParametersTabActions.setTabApiDataFetched({
          [PART_SITE_SOURCE_TAB_ID]: false,
          [SAFETY_STOCK_POLICY_TAB_ID]: false,
          [TIME_PHASED_SAFETY_STOCK_TAB_ID]: false,
          [LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID]: false
        }));

        toast.success("Safety Stock Policy data updated successfully", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });

      } else if (updateReqResponse.data.error) {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Error in updating the Safety Stock Policy data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Event handler for save button click
   * @param {any} event Save button click event data
   */
  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Event handler for reset button click
   * @param {any} params Reset button click event data
   */
  const resetCellEdits = async (params: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'safetyStockPolicy', value: false }));
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  /**
   * Inserts a new row into the grid
   */
  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'safetyStockPolicy', value: true }));
    setChangeTrackState(true);
    setNewRowCount(newRowCount + 1);
  };

  /**
   * Deletes selected rows from the grid
   */
  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  };

  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeLowerSubTabId === SAFETY_STOCK_POLICY_TAB_ID
    ) {
      fetchSafetyStockPolicy(selectedGridSlicers);
    }
  }, [activeLowerSubTabId, tabApiDataFetched, selectedGridSlicers, fetchSafetyStockPolicy]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  return (
    <>
      <div className="row mb-2">
        <div className="col offset-8 col-4 text-end">
          <EditableGridActions
            disableIf={{
              resetBtn: !changeTrackState,
              saveBtn: !changeTrackState,
              deleteRowBtn: !enableDelButton
            }}
            onClick={{
              resetBtn: resetCellEdits,
              saveBtn: onUpdateCellValuesHandler,
              addRowBtn: addRowToGrid,
              deleteRowBtn: deleteSelectedRowsFromGrid
            }}
          />
        </div>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={agRowDataCopy}
          columnDefs={ColDef}
          defaultColDef={defaultColDef}
          onCellEditingStopped={onCellEditingStoppedHandler}
          gridOptions={gridOptions}
          rowSelection={"multiple"}
          modules={AG_GRID_MODULES}
        // suppressAggFuncInHeader={true}
        />
      </div>
    </>
  );
};

export default SafetyStockPolicyTable;

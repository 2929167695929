import { MouseEventHandler } from "react";
import { FaSave } from "react-icons/fa";
import { IoIosAddCircleOutline, IoIosTrash } from "react-icons/io";
import { RxReset } from "react-icons/rx";

const EditableGridActions = (props: {
  disableIf: {
    resetBtn: boolean;
    saveBtn: boolean;
    deleteRowBtn: boolean;
  },
  onClick: {
    resetBtn: MouseEventHandler<HTMLButtonElement>; 
    saveBtn: MouseEventHandler<HTMLButtonElement>;
    addRowBtn: MouseEventHandler<HTMLButtonElement>;
    deleteRowBtn: MouseEventHandler<HTMLButtonElement>;
  }
}) => {
  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="btn-group btn-group-sm me-1" role="group">
        <button
          className="btn btn-danger border border-dark-subtle"
          title="Reset changes"
          onClick={props.onClick.resetBtn}
          disabled={props.disableIf.resetBtn}
        >
          <RxReset size={20} />
        </button>

        <button
          className="btn btn-success border border-dark-subtle"
          title="Save changes"
          onClick={props.onClick.saveBtn}
          disabled={props.disableIf.saveBtn}
        >
          <FaSave size={20} />
        </button>
      </div>

      <button
        className="btn btn-sm text-white me-1"
        style={{ backgroundColor: "#094780" }}
        title="Add a row"
        onClick={props.onClick.addRowBtn}
      >
        <div className="d-flex justify-content-center align-items-center">
          <IoIosAddCircleOutline size={20} /> &nbsp;Add Row
        </div>
      </button>

      <button
        className="btn btn-sm btn-danger text-white me-1"
        title="Delete selected row(s)"
        disabled={props.disableIf.deleteRowBtn}
        onClick={props.onClick.deleteRowBtn}
      >
        <div className="d-flex justify-content-center align-items-center">
          <IoIosTrash size={20} /> &nbsp;Delete Row
        </div>
      </button>
    </div>
  );
};

export default EditableGridActions;

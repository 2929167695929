import { GrPlan } from "react-icons/gr";
import { IoCalculatorOutline, IoReceiptOutline } from "react-icons/io5";
import { MdOutlineSsidChart } from "react-icons/md";
import { useDispatch } from "react-redux";

import TabDetail from "../../../models/TabDetail.model";
import { LSO_BUY_PLAN_TAB_ID, LSO_CALCULATED_SUPPLY_RECEIPTS_TAB_ID, LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID, LSO_S_AND_OP_CHARTS_TAB_ID } from "../../../shared/constants";
import { lotSizeOptimizationTabActions } from "../../../store/slices/lot-size-optimization-tab-slice";
import Tabs from "../../UI/Tabs/Tabs";
import LotSizeOptimizationSummaryGrid from "./LotSizeOptimizationSummaryGrid";
import LSOBuyPlanGrid from "./LSOBuyPlanGrid";
import LSOCalculatedSupplyReceiptsGrid from "./LSOCalculatedSupplyReceiptsGrid";
import LSOPlannedSupplyReceiptsGrid from "./LSOPlannedSupplyReceiptsGrid";
import { SAndOPCharts } from "./LSOS&OPCharts";

const LotSizeOptimizationTab = () => {
    const dispatchFn = useDispatch();
    const subTabs: TabDetail[] = [
        {
            id: LSO_S_AND_OP_CHARTS_TAB_ID,
            name: "S&OP Charts",
            icon: <MdOutlineSsidChart />,
            content: (
                <SAndOPCharts />
            )
        },
        {
            id: LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID,
            name: "Planned Supply Receipts",
            icon: <IoReceiptOutline />,
            content: (
                <LSOPlannedSupplyReceiptsGrid 
                    gridHeight="35vh"
                />
            )
        },
        {
            id: LSO_CALCULATED_SUPPLY_RECEIPTS_TAB_ID,
            name: "Calculated Supply Receipts",
            icon: <IoCalculatorOutline />,
            content: (
                <LSOCalculatedSupplyReceiptsGrid 
                    gridHeight="35vh"
                />
            )
        },
        {
            id: LSO_BUY_PLAN_TAB_ID,
            name: "Buy Plan",
            icon: <GrPlan />,
            content: (
                <LSOBuyPlanGrid 
                    gridHeight="35vh"
                />
            )
        }
    ];

    const setActiveSubTabId = (activeSubTabId: string): void => {
        dispatchFn(lotSizeOptimizationTabActions.setActiveSubTabId(activeSubTabId));
    };

    return (
        <div className="container-fluid">
            <LotSizeOptimizationSummaryGrid 
                gridHeight="37vh"
            />

            <div className="mt-2">
                <Tabs
                    tabs={subTabs}
                    getActiveTabId={setActiveSubTabId} activeTabIndex={0} />
            </div>
        </div>
    );
}

export default LotSizeOptimizationTab;

import { ReactElement } from "react";

const Tab = (props: {
    id: string;
    name: string;
    icon: ReactElement;
    className: string;
    activeTabId: string;
    getTabId: Function;
}) => {
    return (
        <li className={`nav-item ${props.className}`}>
            <a id={`${props.id}-link`} href={`#${props.id}`} role="button" data-bs-toggle="tab" className="nav-link" onClick={(e) => props.getTabId(props.id)}>
                <b className="fs-6 custom-primary-text">
                    {props.icon} {props.name}
                </b>
            </a>
        </li>
    );
};

export default Tab;

import { ChangeEvent, useEffect, useState } from "react";
import { LuPackageSearch, LuWarehouse } from "react-icons/lu";
import { BiReset } from "react-icons/bi";
import { IoChevronForwardCircleOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { controllTowerTabActions } from "../../../store/slices/control-tower-tab-slice";

const ControllTowerSlicers = (props:{
    partSiteSourceMapping:any, slicers:any
}) => {
    const [filteredPropsSlicers, setFilteredPropsSlicers] =
    useState({
        location_names: [],
        part_names: []
    });

    const tempSelectedSlicers = useSelector((state:RootState)=> state.controllTowerTab.tempSelectedSlicers);
    const applyFilter = useSelector((state:RootState)=> state.controllTowerTab.applyFilter);
    const dispatch = useDispatch();
    
      // Set slicers from props to filtered slicers on change
  useEffect(() => {
    // Add "All" slicer item at index 0 for each slicer key.
    let updatedSlicers: any = { ...props.slicers };

    Object.keys(updatedSlicers).forEach((key) => {
      if (Array.isArray(updatedSlicers[key])) {
        const index = updatedSlicers[key].indexOf("All");
        if (index > -1) {
          const updatedArray = [
            "All",
            ...updatedSlicers[key].slice(0, index),
            ...updatedSlicers[key].slice(index + 1),
          ];
          updatedSlicers[key] = updatedArray;
        }
      }
    });

    setFilteredPropsSlicers({ ...updatedSlicers });
  }, [props.slicers]);

    function dropdownFilterChangeHandler(event: ChangeEvent<HTMLInputElement>, key: "part_names"|"location_names"): void {
        setTimeout(() => {
            setFilteredPropsSlicers((prev: any) => {
              const filterText: string = event.target.value;
              const filteredKeySlicers: string[] =
                filterText.length > 0
                  ? [
                    ...props.slicers[key].filter((slicer: any) =>
                      slicer
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                    ),
                  ]
                  : props.slicers[key];
      
              return {
                ...prev,
                [key]: filteredKeySlicers,
              };
            });
          }, 500);
    }

    function addIfNotPresent(arr: string[], element: string) {
        if (!arr.includes(element)) {
          arr.push(element);
        }
      }

      
      const slicerCheckboxChangeHandler = (event: any): void => {
        try {
          const { value, checked } = event.target;
          var newSelectedSlicers: any = { ...tempSelectedSlicers };
          let slicerKey: "part_names" | "location_names" =
            event.target.id.includes("part_name")
              ? "part_names"
                : "location_names";
          let allSlicers = ["part_names", "location_names"];
    
          if (checked) {
            if (value === "All") {
              newSelectedSlicers[slicerKey] = ["All"];
    
            } else {
              newSelectedSlicers[slicerKey] = [...newSelectedSlicers[slicerKey].filter((value: string) => value !== "All"), value];
            }
    
          } else {
            let vb = { ...newSelectedSlicers };
            vb[slicerKey] = vb[slicerKey].filter((v1: any) => v1 !== value);
            vb[slicerKey] = ['All'];
            newSelectedSlicers = { ...vb };
            dispatch(controllTowerTabActions.setTempSelectedSlicers(newSelectedSlicers));
          }
    
          Object.keys(newSelectedSlicers).filter((item:any)=>item!=="number_of_weeks").forEach((key) => {
            if (newSelectedSlicers[key].length === 0) {
              newSelectedSlicers[key] = ["All"];
            }
          });
    
          //Set the other slicers as per mapping when part_code slicer is selected
    
          if (!newSelectedSlicers[slicerKey].includes("All")) {
            var slicerKeyWithAll: string[] = [];
            var slicerKeyWithoutAll: string[] = [];
            if (checked) {
              dispatch(controllTowerTabActions.setTempSelectedSlicers(newSelectedSlicers));
            }
    
            if (
              newSelectedSlicers[slicerKey].includes("All") &&
              newSelectedSlicers[slicerKey].length > 0
            ) {
              newSelectedSlicers[slicerKey] = newSelectedSlicers[slicerKey].filter(
                (v1: any) => v1 !== "All"
              );
            }
            Object.keys(newSelectedSlicers).filter((item:any)=>item!=="number_of_weeks").forEach((el: string) => {
              if (
                newSelectedSlicers[el].findIndex(
                  (v: string) => v === "All"
                ) !== -1
              ) {
                slicerKeyWithAll.push(el);
              } else {
                slicerKeyWithoutAll.push(el);
              }
            });
    
            let filtered = props.partSiteSourceMapping.filter((value: any) => {
              var returnable_boolean: boolean[] = [];
              slicerKeyWithoutAll.forEach((vl: any) => {
                const returnable_boolean1 =
                  newSelectedSlicers[
                    vl
                  ].findIndex((secSli: any) => secSli === value[vl]) !== -1;
                returnable_boolean.push(returnable_boolean1);
              });
              const anyTrue = returnable_boolean.reduce(
                (accumulator: boolean, currentValue: boolean) => {
                  // If the accumulator is already true, it will remain true
                  return accumulator && currentValue;
                },
                true
              );
              return anyTrue;
            });
    
            sessionStorage.setItem(
              "selectedControlTowerSlicers",
              JSON.stringify(newSelectedSlicers)
            );
    
            setFilteredPropsSlicers((prev: any) => {
              let changes = { ...prev };
              slicerKeyWithAll.forEach((el1) => {
                changes[el1] = ["All"];
                filtered.forEach((el: any) => {
                  addIfNotPresent(changes[el1], el[el1]);
                });
              });
              return changes;
            });
          } else {
            dispatch(controllTowerTabActions.setTempSelectedSlicers(newSelectedSlicers));
            let filtered = props.partSiteSourceMapping.filter((value: any) => {
              var returnable_boolean: boolean[] = [];
              allSlicers.forEach((vl: any) => {
                const returnable_boolean1 =
                  newSelectedSlicers[
                    vl
                  ].findIndex((secSli: any) => secSli === value[vl]) !== -1 ||
                  newSelectedSlicers[vl].includes("All");
                returnable_boolean.push(returnable_boolean1);
              });
              const anyTrue = returnable_boolean.reduce(
                (accumulator: boolean, currentValue: boolean) => {
                  // If the accumulator is already true, it will remain true
                  return accumulator && currentValue;
                },
                true
              );
              return anyTrue;
            });
    
            let filtered1 = props.partSiteSourceMapping.filter((value: any) => {
              var returnable_boolean: boolean[] = [];
              allSlicers
                .filter((value) => newSelectedSlicers[value].includes("All"))
                .forEach((vl: any) => {
                  const returnable_boolean1 =
                    newSelectedSlicers[
                      vl
                    ].findIndex((secSli: any) => secSli === value[vl]) !== -1 ||
                    newSelectedSlicers[vl].includes("All");
                  returnable_boolean.push(returnable_boolean1);
                });
              const anyTrue = returnable_boolean.reduce(
                (accumulator: boolean, currentValue: boolean) => {
                  // If the accumulator is already true, it will remain true
                  return accumulator && currentValue;
                },
                true
              );
              return anyTrue;
            });
    
            setFilteredPropsSlicers((prev: any) => {
              let changes = { ...prev };
              allSlicers
                .filter((value) => newSelectedSlicers[value].includes("All"))
                .forEach((el1) => {
                  changes[el1] = ["All"];
                  filtered.forEach((el: any) => {
                    addIfNotPresent(changes[el1], el[el1]);
                  });
                });
              allSlicers
                .filter((value) => !newSelectedSlicers[value].includes("All"))
                .forEach((el1) => {
                  changes[el1] = ["All"];
                  filtered1.forEach((el: any) => {
                    addIfNotPresent(changes[el1], el[el1]);
                  });
                });
              return changes;
            });
          }
        } catch (error: any) {
          console.error(error);
        }
      };


    function setSelectedFilters(event:any): void {
        dispatch(
            controllTowerTabActions.setSelectedGridSlicers(
             tempSelectedSlicers
            )
          );

          dispatch(controllTowerTabActions.resetTabApiDataFetched(null));
          dispatch(controllTowerTabActions.setApplyFilter(!applyFilter));
    }


    const resetSelectedFilters = (event: any) => {
        if (
          tempSelectedSlicers.part_names.toString() !== ["All"].toString() ||
          tempSelectedSlicers.location_names.toString() !== ["All"].toString()
          // props.selectedSlicers.Planning_Bucket !== 12
        ) {
          const newSelectedSlicers = {
            part_names: ["All"],
            location_names: ["All"],
            number_of_weeks: 0
          };
    
          sessionStorage.setItem(
            "selectedControlTowerSlicers",
            JSON.stringify(newSelectedSlicers)
          );
          dispatch(controllTowerTabActions.setTempSelectedSlicers({ ...newSelectedSlicers }));
          dispatch(
            controllTowerTabActions.setSelectedGridSlicers({
              ...newSelectedSlicers,
            })
          );
          dispatch(controllTowerTabActions.resetTabApiDataFetched(null));
        }
      };

      const numOfWeeksChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let numberOfWeeks: number = parseInt(event.target.value);
        numberOfWeeks = (numberOfWeeks >= 0) ? numberOfWeeks : 0;
        dispatch(controllTowerTabActions.setTempSelectedSlicers({
            ...tempSelectedSlicers,
            number_of_weeks: numberOfWeeks
        }));
        dispatch(controllTowerTabActions.setSelectedGridSlicers({
            ...tempSelectedSlicers,
            number_of_weeks: numberOfWeeks
        }));
    }

  return (
    <>
    <div className="col">
        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuPackageSearch /> &nbsp;Part Name: {(
                (tempSelectedSlicers.part_names.length > 1) ?
                  "Multiple" : tempSelectedSlicers.part_names[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "part_names")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.part_names.map(
                (partCode: any, partCodeIdx: any) =>
                  partCode === "All" ? (
                    <li key={`part_name-${partCodeIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`part_name-${partCodeIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={partCode}
                            checked={
                              tempSelectedSlicers.part_names.findIndex(
                                (value: string) => value === "All"
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`part_name-${partCodeIdx}`}
                            className="form-check-label"
                          >
                            {partCode}
                          </label>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={`part_name-${partCodeIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`part_name-${partCodeIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={partCode}
                            checked={
                              tempSelectedSlicers.part_names.findIndex(
                                (value: string) => value === partCode
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`part_name-${partCodeIdx}`}
                            className="form-check-label"
                          >
                            {partCode}
                          </label>
                        </div>
                      </a>
                    </li>
                  )
              )}
          </ul>
        </div>


        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Site Name: {(
                (tempSelectedSlicers.location_names.length > 1) ?
                  "Multiple" : tempSelectedSlicers.location_names[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "location_names")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.location_names.map((op: any, opIdx: any) =>
                op === "All" ? (
                  <li key={`location_name-${opIdx}`}>
                    <a className="dropdown-item">
                      <div className="form-check text-dark">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`location_name-${opIdx}`}
                          onChange={slicerCheckboxChangeHandler}
                          value={op}
                          checked={
                            tempSelectedSlicers.location_names.findIndex(
                              (value: string) => value === "All"
                            ) !== -1
                          }
                        />
                        <label
                          htmlFor={`location_name-${opIdx}`}
                          className="form-check-label"
                        >
                          {op}
                        </label>
                      </div>
                    </a>
                  </li>
                ) : (
                  <li key={`location_name-${opIdx}`}>
                    <a href="#!" className="dropdown-item">
                      <div className="form-check text-dark">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`location_name-${opIdx}`}
                          onChange={slicerCheckboxChangeHandler}
                          value={op}
                          checked={
                            tempSelectedSlicers.location_names.findIndex(
                              (value: string) => value === op
                            ) !== -1
                          }
                        />
                        <label
                          htmlFor={`location_name-${opIdx}`}
                          className="form-check-label"
                        >
                          {op}
                        </label>
                      </div>
                    </a>
                  </li>
                )
              )}
          </ul>
        </div>

        <input 
                className="form-control form-control-sm border-1 border-dark rounded-2 segoe-font"
                type="number" 
                min={0}
                max={props.slicers.number_of_weeks}
                step={1}
                onChange={numOfWeeksChangeHandler}
                value={tempSelectedSlicers.number_of_weeks}
                // defaultValue={tempSelectedSlicers.number_of_weeks}
                style={{
                    display: "inline",
                    width: "100px", 
                    paddingBottom: "8px", 
                    marginRight:"5px", 
                    textAlign: "center"
                }}
                placeholder="Weeks" 
            />

        <div className="d-inline me-1">
          <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={setSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <IoChevronForwardCircleOutline size={20} /> &nbsp;Apply filters
            </div>
          </button>
        </div>

        <div className="d-inline">
          <button type="button" className="btn btn-sm btn-warning" onClick={resetSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <BiReset size={20} /> &nbsp;Reset filters
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default ControllTowerSlicers;

import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { LuAlignHorizontalSpaceAround, LuBoxes, LuPackage, LuParkingCircle } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TabDetail from "../../../models/TabDetail.model";
import { PART_MASTER_TAB_ID, SITE_MASTER_TAB_ID, SOURCE_MASTER_TAB_ID, SUPPLIER_MASTER_TAB_ID } from "../../../shared/constants";
import { RootState } from "../../../store";
import { masterTablesTabActions } from "../../../store/slices/master-tables-tab-slice";
import Tabs from "../../UI/Tabs/Tabs";
import PartMasterGrid from "./PartMasterGrid/PartMasterGrid";
import SiteMasterGrid from "./SiteMasterGrid/SiteMasterGrid";
import SourceMasterGrid from "./SourceMasterGrid/SourceMasterGrid";
import SupplierMasterGrid from "./SupplierMasterGrid/SupplierMasterGrid";

const MasterTablesTab = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, navigate])

    // Redux variables
    const dispatchFn = useDispatch();
    const activeSubTabId: string = useSelector((state: RootState) => state.masterTables.activeSubTabId);
    const isMasterTablesEdited = useSelector((state: RootState) => state.masterTables.isMasterTablesEdited);
    const partMasterTabName = `Part Master ${(
        isMasterTablesEdited.partMaster ? '*' : ''
    )}`;
    const sourceMasterTabName = `Source Master ${(
        isMasterTablesEdited.sourceMaster ? '*' : ''
    )}`;
    const siteMasterTabName = `Site Master ${(
        isMasterTablesEdited.siteMaster ? '*' : ''
    )}`;
    const supplierMasterTabName = `Supplier Master ${(
        isMasterTablesEdited.supplierMaster ? '*' : ''
    )}`;

    const subTabs: TabDetail[] = [
        {
            id: SOURCE_MASTER_TAB_ID,
            name: sourceMasterTabName,
            icon: <LuPackage />,
            content: <SourceMasterGrid gridHeight={"70vh"} />
        },
        {
            id: SITE_MASTER_TAB_ID,
            name: siteMasterTabName,
            icon: <LuAlignHorizontalSpaceAround />,
            content: <SiteMasterGrid gridHeight={"70vh"} />
        },
        {
            id: PART_MASTER_TAB_ID,
            name: partMasterTabName,
            icon: <LuParkingCircle />,
            content: <PartMasterGrid gridHeight={"70vh"} />
        },
        {
            id: SUPPLIER_MASTER_TAB_ID,
            name: supplierMasterTabName,
            icon: <LuBoxes />,
            content: <SupplierMasterGrid gridHeight={"70vh"} />
        }
    ];

    const setActiveSubTabId = (activeSubTabId: string): void => {
        dispatchFn(masterTablesTabActions.setActiveSubTabId(activeSubTabId));
    };

    return (
        <div className="container-fluid">
            <div className="mt-2">
                <Tabs
                    tabs={subTabs}
                    activeTabIndex={subTabs.findIndex(tab => tab.id === activeSubTabId)}
                    getActiveTabId={setActiveSubTabId}
                />
            </div>
        </div>
    );
};

export default MasterTablesTab;

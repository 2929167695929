import React, { MouseEventHandler, ReactNode } from "react";

const Modal = React.forwardRef((props: {
    id: string;
    title: string;
    footer?: any;
    onModalClose?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
  }, ref: React.ForwardedRef<HTMLButtonElement>) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${props.id}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={`${props.id}Label`}>
              {props.title}
            </h1>
            <button
              ref={ref}
              type="button"
              className="btn-close"
              title="Close Modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onModalClose ? props.onModalClose : (e) => {}}
            ></button>
          </div>
          
          <div className="modal-body">
            {props.children}
          </div>
          
          {props.footer && (
            <div className="modal-footer">
              {props.footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Modal;

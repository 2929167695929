import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";

const DemandPlanningTab = (): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const navigateTo = useNavigate();
    useEffect(()=>{
        if (!isAuthenticated){
            navigateTo("/login");
        }
    },[isAuthenticated, navigateTo])

    return (
        <p>Coming soon...</p>
    );
};

export default DemandPlanningTab;

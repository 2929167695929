import { createSlice, Reducer, Slice } from "@reduxjs/toolkit";
import {
  LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID,
  LSO_CALCULATED_SUPPLY_RECEIPTS_TAB_ID,
  LSO_BUY_PLAN_TAB_ID,
  LSO_S_AND_OP_CHARTS_TAB_ID,
} from "../../shared/constants";
import ReplenishmentPlanSummary from "../../models/ReplenishmentPlan/ReplenishmentPlanSummary.model";
import LotSizeOptimizationTabSlice from "../../models/Redux/LotSizeOptimizationTabSlice.model";
import {
  LSOApiSlicerModel,
  LSOSelectedSlicerModel,
  LSOSlicerDropdownModel,
} from "../../models/LotSizeOptimization/LSOSlicer.model";
import { getDefaultLSOSlicers } from "../../shared/functions";

const defaultLSOSlicers: LSOSelectedSlicerModel =
  getDefaultLSOSlicers();

const initialState: LotSizeOptimizationTabSlice = {
  summaryGridRowData: [],
  tabApiDataFetched: {
    [LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false,
    [LSO_CALCULATED_SUPPLY_RECEIPTS_TAB_ID]: false,
    [LSO_BUY_PLAN_TAB_ID]: false,
  },
  activeSubTabId: LSO_S_AND_OP_CHARTS_TAB_ID,
  tempSelectedSlicers: defaultLSOSlicers,
  selectedSlicers: defaultLSOSlicers,
  refreshSummaryGrid: false,
  slicers: {
    group_policy_name: [],
    site_codes: [],
    part_code: [],
    number_of_weeks: 0,
  },
};

const lotSizeOptimizationTabSlice: Slice = createSlice({
  name: "lotSizeOptimizationTab",
  initialState,
  reducers: {
    setSlicers: (
      state: LotSizeOptimizationTabSlice,
      action: { payload: LSOApiSlicerModel }
    ) => {
      const tempSlicers: LSOSlicerDropdownModel = {
        site_codes: [],
        part_code: [],
        group_policy_name: [],
        number_of_weeks: 0,
      };

      tempSlicers.number_of_weeks = action.payload.weeks;

      action.payload.sites_group_policies.forEach((site: any) => {
        const exists = tempSlicers.site_codes.some(
          (item: any) =>
            item.name === site.location_display_name &&
            item.value === site.location_code
        );
        if (!exists) {
          tempSlicers.site_codes.push({
            name: site.location_display_name,
            value: site.location_code,
          });
        }
      });

      tempSlicers.site_codes.unshift({ name: "All", value: "All" });

      // Get Group Policy Slicers
      let temp_policies: any[] = action.payload.sites_group_policies.map(
        (site: any) => site.group_policy_name
      );
      temp_policies = new Set(temp_policies) as any;
      temp_policies = [...temp_policies];

      const group_policies = temp_policies.map<{
        name: string;
        value: string;
      }>((policy: string) => ({
        name: policy,
        value: policy,
      }));

      tempSlicers.group_policy_name = group_policies;

      // Get Part Name Slicers
      tempSlicers.part_code = action.payload.sites_group_policies.map(
        (part: any) => {
          return {
            name: part.part_code,
            value: part.part_code,
            group_policy_name: part.group_policy_name,
          };
        }
      );

      state.slicers = tempSlicers;
    },
    setTabApiDataFetched: (
      state: LotSizeOptimizationTabSlice,
      action: { payload: { [key: string]: boolean } }
    ) => {
      state.tabApiDataFetched = {
        ...state.tabApiDataFetched,
        ...action.payload,
      };
    },
    setRefreshSummaryGrid: (
      state: LotSizeOptimizationTabSlice,
      action: { payload: boolean }
    ) => {
      state.refreshSummaryGrid = action.payload;
    },
    setActiveSubTabId: (
      state: LotSizeOptimizationTabSlice,
      action: { payload: string }
    ) => {
      state.activeSubTabId = action.payload;
    },
    setGridRowData(
      state: LotSizeOptimizationTabSlice,
      action: { payload: ReplenishmentPlanSummary[] }
    ) {
      state.summaryGridRowData = action.payload;
    },
    setTempSelectedSlicers: (
      state: LotSizeOptimizationTabSlice,
      action: { payload: LSOSelectedSlicerModel }
    ) => {
      state.tempSelectedSlicers = action.payload;
    },
    setSelectedSlicers: (state: LotSizeOptimizationTabSlice, action) => {
      state.selectedSlicers = action.payload;
    },
    resetSelectedSlicers: (state: LotSizeOptimizationTabSlice, action) => {
      state.tempSelectedSlicers = JSON.parse(JSON.stringify(defaultLSOSlicers));
      state.selectedSlicers = JSON.parse(JSON.stringify(defaultLSOSlicers));
    },
    resetTabApiDataFetched: (state: LotSizeOptimizationTabSlice, action) => {
      state.tabApiDataFetched = {
        [LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false,
        [LSO_CALCULATED_SUPPLY_RECEIPTS_TAB_ID]: false,
        [LSO_BUY_PLAN_TAB_ID]: false,
      };
    },
  },
});

export const lotSizeOptimizationTabActions = lotSizeOptimizationTabSlice.actions;
export default lotSizeOptimizationTabSlice.reducer as Reducer;

import { createSlice, Reducer, Slice } from "@reduxjs/toolkit";

import { ReplPlanAPISlicerModel, ReplPlanSelectedSlicerModel, ReplPlanSlicerDropdownModel } from "../../models/ReplenishmentPlan/ReplPlanSlicer.model";
import { getDefaultControlTowerSlicersInStorage, getDefaultReplPlanSlicers } from "../../shared/functions";
import {CONTROL_TOWER_TAB_ID, CUTS_OBSERVER, INVENTORY_TRACKING_ID, WEEKS_OF_SUPPLY_ID } from "../../shared/constants";
import ReplenishmentPlanSummary from "../../models/ReplenishmentPlan/ReplenishmentPlanSummary.model";
import ControlTowerTabSlice from "../../models/Redux/ControlTowerTabSlice.model";

let defaultSlicers = getDefaultControlTowerSlicersInStorage();
const initialState: ControlTowerTabSlice = {
    summaryGridRowData: [],
    activeSubTabId: {
        upper: CUTS_OBSERVER,
        lower: WEEKS_OF_SUPPLY_ID
    },
    tempSelectedSlicers: defaultSlicers,
    selectedSlicers: defaultSlicers,
    isReplenishmentPlanTabEditing: false,
    refreshSummaryGrid: false,
    tabApiDataFetched: {
        [CUTS_OBSERVER]: false,
        [INVENTORY_TRACKING_ID]: false,
        [WEEKS_OF_SUPPLY_ID]: false
    },
    slicers: defaultSlicers,
    mappings: {},
    selectedGridSlicers: defaultSlicers,
    applyFilter: false,
    }

const controlTowerTabSlice: Slice = createSlice({
    name: "controlTowerTab",
    initialState,
    reducers: {
        setSelectedGridSlicers: (state, action) => {
            state.selectedGridSlicers = action.payload;
        },
        setMappings(state, actions){
            state.mappings = actions.payload;
        },
        setSlicers: (state, action) => {
            let temp:any = {};
            temp["location_names"] = action.payload.location_names;
            temp["part_names"] = action.payload.part_names;
            state.slicers = temp;
        },
        setGridRowData(state, action: { payload: ReplenishmentPlanSummary[] }) {
            state.summaryGridRowData = action.payload;
        },
        setActiveSubTabId: (state, action: {
            payload: {
                upper?: string;
                lower?: string;
            }
        }) => {
            if (action.payload.upper) {
                state.activeSubTabId.upper = action.payload.upper;
            }

            if (action.payload.lower) {
                state.activeSubTabId.lower = action.payload.lower;
            }
        },
        setTabApiDataFetched: (state, action: { payload: { [key: string]: boolean; } }) => {
            state.tabApiDataFetched = {
                ...state.tabApiDataFetched,
                ...action.payload
            };
        },
        setPartSlicer: (state, action: { payload: string }) => {
            state.slicers.part_codes = state.slicers.mappings[action.payload];
            state.tempSelectedSlicers.part_code = state.slicers.part_codes[0].value;
        },
        setRefreshSummaryGrid: (state, action: { payload: boolean }) => {
            state.refreshSummaryGrid = action.payload;
        },
        setTempSelectedSlicers: (state, action: { payload: ReplPlanSelectedSlicerModel }) => {
            state.tempSelectedSlicers = action.payload;
        },
        setSelectedSlicers: (state, action) => {
            state.selectedSlicers = state.tempSelectedSlicers;
        },
        setIsReplenishmentPlanTabEditing: (state, action: { payload: boolean }) => {
            state.isReplenishmentPlanTabEditing = action.payload;
        },
        resetSelectedSlicers: (state, action) => {
            const defaultSlicers: ReplPlanSelectedSlicerModel = getDefaultReplPlanSlicers();
            state.tempSelectedSlicers = JSON.parse(JSON.stringify(defaultSlicers));
            state.selectedSlicers = JSON.parse(JSON.stringify(defaultSlicers));
        },
        resetTabApiDataFetched: (state, action) => {
            state.tabApiDataFetched = {
                [CUTS_OBSERVER]: false,
                [INVENTORY_TRACKING_ID]: false,
                [WEEKS_OF_SUPPLY_ID]: false
            };
        },
        setApplyFilter: (state, action) => {
            state.applyFilter = action.payload;
        }
    }
});

export const controllTowerTabActions = controlTowerTabSlice.actions;

export default controlTowerTabSlice.reducer as Reducer<ControlTowerTabSlice>;
